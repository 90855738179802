import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/App.scss'
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home'
import You from './pages/You'
import Stories from './pages/Stories'
import StoryFeed from './pages/StoryFeed';
import StoryDetails from './pages/StroryDetails';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './pages/NotFound';
import FAQ from './pages/FAQ';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Profile from './pages/Profile';
import MyStories from './pages/MyStories';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword.js';
import EmailVerification from './pages/EmailVerification.js';
import ConsentForm from './pages/ConsentForm.js'
import EditStory from './pages/EditStory';

const App = () => {

  const [sideMenu, setSideMenu] = useState(false)

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
        <Route exact path='/you' element={<You sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
        <Route exact path='/stories' element={<Stories sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
        <Route exact path='/stories/:slug' element={<StoryFeed sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
        <Route exact path='/story/:slug' element={<StoryDetails sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
        <Route exact path='/faqs' element={ <FAQ sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/login' element={ <Login sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/signup' element={ <Register sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/forgot-password' element={ <ForgotPassword sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/password/reset/:link' element={ <ResetPassword sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/verify-email/:link' element={ <EmailVerification sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/consent-form-link/:user' element={ <ConsentForm sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/edit-story/:slug' element={ <EditStory sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        
        <Route exact path='/profile' element={ <Profile sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>
        <Route exact path='/my-stories' element={ <MyStories sideMenu={sideMenu} setSideMenu={setSideMenu} /> }></Route>

        <Route path='*' element={<NotFound sideMenu={sideMenu} setSideMenu={setSideMenu} />}></Route>
      </Routes>
      <ScrollToTop />
    </Router>
  )
}

export default App