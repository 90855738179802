import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const YouBanner = () => {
  const getCoordinates = () => {
    let randomTop = Math.floor(Math.random() * 50);
    let randomLeft = Math.floor(Math.random() * 50);
    return [randomTop, randomLeft];
  };

  const getRandomIndex = (total) => {
    return Math.floor(Math.random() * total)
  }

  const shuffle = (array) => {
    var i = array.length,
        j = 0,
        temp;
    while (i--) {

        j = Math.floor(Math.random() * (i+1));
        // swap randomly chosen element with current element
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
  }

  const animateMapHeads = () => {
    let mapHeads = document.querySelectorAll(".map-heads");
    // let randomIndex = getRandomIndex(mapHeads.length)
    let randomIndex = shuffle([0,1,2,3,4,5,6,7,8])
    randomIndex = randomIndex[getRandomIndex(mapHeads.length)]
    // let coordinates = getCoordinates();
    // mapHeads[randomIndex].style.top = `${coordinates[0]}%`;
    // mapHeads[randomIndex].style.left = `${coordinates[1]}%`;
    let tl = gsap.timeline();
    tl.to(mapHeads[randomIndex], {
      y: "-90%",
      opacity: 1,
      duration: 2.5,
    })
    .to(mapHeads[randomIndex], {
      opacity: 0,
      duration: .5,
    })
    .to(mapHeads[randomIndex], {
      y: 0,
    });
  };

  useEffect(() => {
    let interval = setInterval(() => {
        animateMapHeads();
    }, 1000);

    return () => {
        clearInterval(interval)
    }

  });

  const scrollIntoView = () => {
    setTimeout(() => {
      let el = document.getElementById('you-form')
      el.scrollIntoView()
    }, 0)
  }
  

  return (
    <section className="you-banner">
      <div className="banner-text">
        <div className="container">
            <div className="d-flex align-items-start align-items-lg-center justify-content-between flex-column flex-lg-row">
                <figure className="mb-4 mb-lg-0 hul-logo d-none d-lg-block">
                    <img src="/images/you/logo-symbol-hul.png" alt="Hindustan Unilever" />
                </figure>
              <div className="headings">
                <span className="h3">YOU are inspiring! Tell us your story.</span>
                <p>
                  Inspire thousands of people via our social media.
                </p>
                {/* <button onClick={scrollIntoView} className="btn btn-custom">
                  <span className="text">Tell Us Your Story</span>
                  <span className="icon">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96.000000pt"
                      height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                        fill="#005eff"
                        stroke="none"
                      >
                        <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                      </g>
                    </svg>
                  </span>
                </button> */}
              </div>
              <div className="d-flex d-lg-none align-items-center justify-content-between w-100 mobile-images">
                <figure className="hul">
                  <img src="/images/you/logo-symbol-hul.png" alt="Hindustan Uniliver" />
                </figure>
                <figure className="hob">
                  <img src="/images/you/hob.svg" alt="Humans Of Bombay" />
                </figure>
              </div>
              <figure className="mb-0 hob-logo d-none d-lg-block">
                  <img src="/images/you/hob.svg" alt="Humans Of Bombay" />
              </figure>

            </div>
        </div>
      </div>
      <div className="map-heads one">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images1.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads two">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images2.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads three">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images3.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads four">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images4.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads five">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images5.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads six">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images6.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads seven">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images7.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads eight">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images8.png" alt="User" />
        </Link>
      </div>
      <div className="map-heads nine">
        <Link to={`#`}>
          <img src="/images/you/share-your-story-images9.png" alt="User" />
        </Link>
      </div>
      <div className="scroll-down">
          <a href="#you-form"> <span></span> Scroll Down</a>
      </div>
    </section>
  );
};

export default YouBanner;
