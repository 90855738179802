import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Feed from '../components/Feed'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import ThemeSlider from '../components/ThemeSlider'
import MetaData from '../helpers/MetaData'
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'

const StoryFeed = ({ setSideMenu, sideMenu }) => {

  const [themes, setThemes] = useState([])
  const { slug } = useParams()
  const [feeds, setFeeds] = useState([])
  const [theme, setTheme] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const fetchThemes = async () => {
      try {
          let res = await ApiService.fetchCampusTheme()
          setThemes(res.themes)
      } catch (error) {
          console.log('error')
          notification('Something went wrong! Please try refreshing the page.')
      }
  }

  const fetchFeeds = async () => {
    try {
      let res = await ApiService.fetchFeeds(slug, page)
      setFeeds(res.stories)
      setTheme(res.theme)
      setTotalPages(res.total)
    } catch (error) {
      console.log(error)
      notification('Something went wrong! Please try refreshing the page.', 'error')      
    }

  }

  const changePage = (num) => {
    setTimeout(() => {
      let el = document.getElementById('feeds')
      el.scrollIntoView()
    }, 0)
    setPage(num)
  }

  useEffect(() => {
      fetchThemes()
      fetchFeeds()
  }, [slug, page])

  return (
    <main>
        <MetaData title='Story Feeds | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
        <Header setSideMenu={setSideMenu} />
        {
          themes
          &&
          <ThemeSlider themes={themes} slug={slug} />
        }
        {
          (feeds && theme)
          &&
          <Feed feeds={feeds} theme={theme} page={page} changePage={changePage} totalPages={totalPages} />
        }
        <Footer />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
  )
}

export default StoryFeed