import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PageNotFound from '../components/PageNotFound'
import SideMenu from '../components/SideMenu'
import MetaData from '../helpers/MetaData'

const NotFound = ({ setSideMenu, sideMenu }) => {
  return (
    <main>
      <MetaData title='Not Found | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
      <Header setSideMenu={setSideMenu} />
      <PageNotFound />
      <Footer />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
  )
}

export default NotFound