import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AppContext from "../context/AppContext";
import notification from "../helpers/notification";
import ApiService from "../service/ApiService";

const UpdateProfile = () => {
  const { user, fetchUser } = useContext(AppContext);

  const [campusOptions, setCampusOptions] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    campus: "",
    designation: "",
  });
  const [changePasswordData, setChangePasswordData] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  })

  const fetchCampusTheme = async () => {
    try {
      let res = await ApiService.fetchCampusTheme();
      setCampusOptions(
        res.campuses.map((campus) => ({
          value: campus._id,
          label: campus.title,
        }))
      );
    } catch (error) {
      console.log(error);
      notification(
        "Unable to fetch campus and theme. Please check your connection and try again!",
        "error"
      );
    }
  };

  const fetchDesignation = async () => {
    try {
      const res = await ApiService.fetchDesignation();
      setDesignation(
        res.designation.map((des) => ({
          value: des._id,
          label: des.title,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
        const {message} = await ApiService.updateProfile(userDetails)
        notification(message, 'success');
        fetchUser()
    } catch (err) { 
      console.log(err);
      notification(err.response.data.message, 'error');
    }
  };

  const handleChangePassword = async (e) => {
    try {
      e.preventDefault();
      const {message} = await ApiService.changePassword(changePasswordData)
      notification(message, 'success')
      setChangePasswordData({
        currentPassword: '',
        password: '',
        confirmPassword: '',
      })
    }catch(err) {
      console.log(err);
      notification(err.response.data.message, 'error');
    }
  }

  useEffect(() => {
    fetchCampusTheme();
    fetchDesignation();
  }, []);

  useEffect(() => {
    if (user) {
      setUserDetails({
        firstName: user.name.split(' ')[0],
        lastName: user.name.split(' ')[1],
        email: user.email,
        campus: user.campus,
        designation: user.designation,
      });
    }
  }, [user]);

  return (
    <section className="tell-your-story auth-content py-5">
      <div className="container">
        <div className="headings mb-4">
          <span className="h4 d-block">Profile</span>
          <p>Update your profile</p>
        </div>
        <div className="you-form-wrapper">
          <div className="form-content auth">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                <div className="form-group">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your first name"
                  // required
                  value={userDetails.firstName}
                  onChange={(e) =>
                    setUserDetails((prevData) => ({
                      ...prevData,
                      firstName: e.target.value,
                    }))
                  }
                />
              </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your last name"
                  // required
                  value={userDetails.lastName}
                  onChange={(e) =>
                    setUserDetails((prevData) => ({
                      ...prevData,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
                </div>
              </div>
              {/* <div className="form-group">
                <label htmlFor="name">Name *</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter your name"
                  // required
                  value={userDetails.name}
                  onChange={(e) =>
                    setUserDetails((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  readOnly
                  value={userDetails.email}
                  // onChange={(e) =>
                  //   setUserDetails((prevData) => ({
                  //     ...prevData,
                  //     email: e.target.value,
                  //   }))
                  // }
                />
              </div>
              <div className="form-group">
                <label htmlFor="campus">Base Location *</label>
                <Select
                  options={campusOptions}
                  isSearchable={true}
                  placeholder={`Choose Base Location`}
                  className={`react-select-container`}
                  classNamePrefix={"react-select"}
                  value={campusOptions.find((op) => {
                    return op.value === userDetails.campus;
                  })}
                  id={"campus"}
                  onChange={(e) =>
                    setUserDetails((prevData) => ({
                      ...prevData,
                      campus: e.value,
                    }))
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="designation">I Work In *</label>
                <Select
                  options={designation}
                  isSearchable={true}
                  placeholder={`Choose Department`}
                  className={`react-select-container`}
                  classNamePrefix={"react-select"}
                  value={designation.find((op) => {
                    return op.value === userDetails.designation;
                  })}
                  id={"designation"}
                  onChange={(e) =>
                    setUserDetails((prevData) => ({
                      ...prevData,
                      designation: e.value,
                    }))
                  }
                />
              </div>
              <div className="form-group mt-4 next-btn-wrapper">
                <button type="submit" className="btn btn-custom">
                  <span className="text">Save</span>
                  <span className="icon">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96.000000pt"
                      height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                        fill="#9d44c0"
                        stroke="none"
                      >
                        <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="headings mb-4 mt-5">
          <span className="h4 d-block">Change Password</span>
        </div>
        <div className="mt-5 you-form-wrapper">
          <div className="form-content auth">
          <form onSubmit={handleChangePassword}>
          <div className="form-group">
                <label htmlFor="currentPassword">Current Password *</label>
                <input
                  type="password"
                  name="currentPassword"
                  className="form-control"
                  id="currentPassword"
                  // required
                  placeholder="Current Password"
                  value={changePasswordData.currentPassword}
                  onChange={(e) =>
                    setChangePasswordData((prevData) => ({
                      ...prevData,
                      currentPassword: e.target.value,
                    }))
                  }
                />
              </div>
          <div className="form-group">
                <label htmlFor="password">New Password *</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="password"
                  // required
                  placeholder="Current Password"
                  value={changePasswordData.password}
                  onChange={(e) =>
                    setChangePasswordData((prevData) => ({
                      ...prevData,
                      password: e.target.value,
                    }))
                  }
                />
              </div>
          <div className="form-group">
                <label htmlFor="confirmPassword">Confirm New Password *</label>
                <input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  id="confirmPassword"
                  // required
                  placeholder="Current Password"
                  value={changePasswordData.confirmPassword}
                  onChange={(e) =>
                    setChangePasswordData((prevData) => ({
                      ...prevData,
                      confirmPassword: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="form-group mt-4 next-btn-wrapper">
                <button type="submit" className="btn btn-custom">
                  <span className="text">Change Password</span>
                  <span className="icon">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96.000000pt"
                      height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                        fill="#9d44c0"
                        stroke="none"
                      >
                        <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
          </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateProfile;
