import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import AppContext from "../context/AppContext";
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'
import {FiCheck} from 'react-icons/fi'
import {CgClose} from 'react-icons/cg'

const UserRegister = () => {

  const {fetchUser} = useContext(AppContext)
  const navigate = useNavigate()

  const [campusOptions, setCampusOptions] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [error, setError] = useState({})
  const [passError, setPassError] = useState({})
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    campus:'',
    designation: ''
  })
  const fetchCampusTheme = async () => {
    try {
      let res = await ApiService.fetchCampusTheme();
      setCampusOptions(
        res.campuses.map((campus) => ({
          value: campus._id,
          label: campus.title,
        }))
      );
    } catch (error) {
      console.log(error);
      notification(
        "Unable to fetch campus and theme. Please check your connection and try again!",
        "error"
      );
    }
  };

  const fetchDesignation = async() => {
    try {

      const res = await ApiService.fetchDesignation()
      setDesignation(
        res.designation.map((des) => ({
          value: des._id,
          label: des.title
        }))
        )

    } catch (err) {
      console.log(err)
    }
  }

  const validate = userDetails => {
    setError({})
    setPassError({})
    let emailRegex = /^[\w.+\-]+@unilever\.com$/
    let passLength = new RegExp('(?=.{12,})')
    let passUpper = new RegExp('(?=.*[A-Z])')
    let passLower = new RegExp('(?=.*[a-z])')
    let passDigit = new RegExp('(?=.*[0-9])')
    let passChar = new RegExp('([^A-Za-z0-9])')
    let hasError = false;
    if(!userDetails.firstName.trim()){
      hasError = true;
      setError(prev => ({
        ...prev,
        firstName: 'Please enter your first name'
      }))
    }
    if(!userDetails.lastName.trim()){
      hasError = true;
      setError(prev => ({
        ...prev,
        lastName: 'Please enter your last name'
      }))
    }
    if(!userDetails.email.trim()){
      hasError = true;
      setError(prev => ({
        ...prev,
        email: 'Please enter your email'
      }))
    }
    if(!emailRegex.test(userDetails.email)) {
      hasError = true;
      setError(prev => ({
        ...prev,
        email: 'Please use official email ID to create account'
      }))
    }
    if(!userDetails.password){
      hasError = true;
      setError(prev => ({
        ...prev,
        password: 'Please enter password'
      }))
    }
    if(!passLength.test(userDetails.password)){
      hasError = true;
      setPassError(prev => ({
        ...prev,
        length: false
      }))
    }
    if(!passUpper.test(userDetails.password)){
      hasError = true;
      setPassError(prev => ({
        ...prev,
        upper: false
      }))
    }
    if(!passLower.test(userDetails.password)){
      hasError = true;
      setPassError(prev => ({
        ...prev,
        lower: false
      }))
    }
    if(!passDigit.test(userDetails.password)){
      hasError = true;
      setPassError(prev => ({
        ...prev,
        digit: false
      }))
    }
    if(!passChar.test(userDetails.password)){
      hasError = true;
      setPassError(prev => ({
        ...prev,
        char: false
      }))
    }
    if(!userDetails.confirmPassword){
      hasError = true;
      setError(prev => ({
        ...prev,
        confirmPassword: 'Please enter confirm password'
      }))
    }
    if(userDetails.password !== userDetails.confirmPassword){
      hasError = true;
      setError(prev => ({
        ...prev,
        notSame: 'Password and confirm password does not match'
      }))
    }
    if(!userDetails.campus){
      hasError = true;
      setError(prev => ({
        ...prev,
        campus: 'Please select base location'
      }))
    }
    if(!userDetails.designation){
      hasError = true;
      setError(prev => ({
        ...prev,
        designation: 'Please select designation'
      }))
    }
    if(hasError) {
      return false
    }else {
      return true
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let isValidate = validate(userDetails)
    if(isValidate) {
      try {        
        const {message} = await ApiService.register(userDetails);
        notification(message, "success");
        // localStorage.setItem('token', token)
        // fetchUser()
        // navigate('/')
        setUserDetails({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          campus:'',
          designation: ''
        })
        setTimeout(() => {
          navigate('/login')
        },1000)
      }catch(err){
        console.log(err)
        notification(err.response.data.message, 'error')
      } 
    }
  }

  useEffect(() => {
    fetchCampusTheme();
    fetchDesignation();
  }, []);

  return (
    <div className="form-content auth">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
          <div className="form-group">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            name="firstName"
            className="form-control"
            id="firstName"
            placeholder="Enter your first name"
            // required
            value={userDetails.firstName}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                firstName: e.target.value,
              }))
            }
          />
          {
            error.firstName && <small className="text-warning">{error.firstName}</small>
          }
        </div>
          </div>
          <div className="col-lg-6">
          <div className="form-group">
          <label htmlFor="name">Last Name *</label>
          <input
            type="text"
            name="lastName"
            className="form-control"
            id="lastName"
            placeholder="Enter your last name"
            // required
            value={userDetails.lastName}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                lastName: e.target.value,
              }))
            }
          />
          {
            error.lastName && <small className="text-warning">{error.lastName}</small>
          }
        </div>
          </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="Enter your name"
            // required
            value={userDetails.name}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
          />
          {
            error.name && <small className="text-warning">{error.name}</small>
          }
        </div> */}
        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="email"
            // required
            placeholder="Enter your email"
            value={userDetails.email}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                email: e.target.value,
              }))
            }
          />
          {
            error.email && <small className="text-warning">{error.email}</small>
          }
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            type="password"
            name="password"
            className="form-control"
            id="password"
            // required
            placeholder="Enter your password"
            value={userDetails.password}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                password: e.target.value,
              }))
            }
          />
          {
            Object.keys(passError).length > 0 &&
            <div className="password-error-box mt-2">
              <ul className="list-unstyled mb-0">
                <li className={passError.length === undefined ? 'success' : 'failed'}>
                  <span>
                    {passError.length === undefined ? <FiCheck /> : <CgClose/>}
                  </span>
                  <span>
                    Password contains at least 12 characters 
                  </span>
                </li>
                <li className={passError.lower === undefined ? 'success' : 'failed'}>
                  <span>
                    {passError.lower === undefined ? <FiCheck /> : <CgClose/>}
                  </span>
                  <span>
                    Password contains at least one small alphabet
                  </span>
                </li>
                <li className={passError.upper === undefined ? 'success' : 'failed'}>
                  <span>
                    {passError.upper === undefined ? <FiCheck /> : <CgClose/>}
                  </span>
                  <span>
                    Password contains at least one capital alphabet
                  </span>
                </li>
                <li className={passError.char === undefined ? 'success' : 'failed'}>
                  <span>
                    {passError.char === undefined ? <FiCheck /> : <CgClose/>}
                  </span>
                  <span>
                    Password contains at least one special character/symbol
                  </span>
                </li>
                <li className={passError.digit === undefined ? 'success' : 'failed'}>
                  <span>
                    {passError.digit === undefined ? <FiCheck /> : <CgClose/>}
                  </span>
                  <span>
                    Password contains at least one number
                  </span>
                </li>
              </ul>
            </div>
          }
          {
            error.password && <small className="text-warning">{error.password}</small>
          }
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password *</label>
          <input
            type="password"
            name="confirmPassword"
            className="form-control"
            id="confirmPassword"
            // required
            placeholder="Enter your confirm password"
            value={userDetails.confirmPassword}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                confirmPassword: e.target.value,
              }))
            }
          />
          {
            error.confirmPassword && <small className="text-warning">{error.confirmPassword}</small>
          }
          {
            (error.notSame && !error.confirmPassword) && <small className="text-warning">{error.notSame}</small>
          }
        </div>
        <div className="form-group">
          <label htmlFor="campus">Base Location *</label>
          <Select
            options={campusOptions}
            isSearchable={true}
            placeholder={`Choose Base Location`}
            className={`react-select-container`}
            classNamePrefix={"react-select"}
            value={campusOptions.find((op) => {
              return op.value === userDetails.campus;
            })}
            id={"campus"}
            onChange={(e) =>
              setUserDetails((prevData) => ({
                ...prevData,
                campus: e.value,
              }))
            }
            // menuIsOpen={true}
          />
           {
            error.campus && <small className="text-warning">{error.campus}</small>
          }
        </div>
        <div className="form-group">
              <label htmlFor="designation">I Work In *</label>
              <Select
                options={designation}
                isSearchable={true}
                placeholder={`Choose Department`}
                className={`react-select-container`}
                classNamePrefix={"react-select"}
                value={designation.find((op) => {
                  return op.value === userDetails.designation;
                })}
                id={"designation"}
                onChange={(e) =>
                  setUserDetails((prevData) => ({
                    ...prevData,
                    designation: e.value,
                  }))
                }
              />
                  {
            error.designation && <small className="text-warning">{error.designation}</small>
          }
          </div>
        <div className="form-group mt-4 next-btn-wrapper">
          <button type="submit" className="btn btn-custom">
            <span className="text">Register</span>
            <span className="icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="96.000000pt"
                height="96.000000pt"
                viewBox="0 0 96.000000 96.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                  fill="#9d44c0"
                  stroke="none"
                >
                  <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                </g>
              </svg>
            </span>
          </button>
        </div>
        <div className="form-group mb-0">
          <p className="text-end mb-0">
            Already have an account{" "}
            <Link className="text-warning" to="/login">
              Login
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default UserRegister;
