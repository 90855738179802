import { useEffect } from "react"
import { useState } from "react"
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"

const TellStories = () => {

    const [url, setUrl] = useState()
    const formUrl = `${window.location.origin}/you`

    useEffect(() => {
        setUrl(window.location.href)
    }, [window.location])

  return (
    <section className="tell-stories">
        <div className="container">
            <div className="row">
                <div className="col-10 col-lg-6">
                    <div className="headings">
                        <span className="h4">Have a colleague whose story inspires you? Share this form with them.</span>    
                        <p className='my-4'>
                            Help their story reach many more!
                        </p>
                        {/* <Link to='/you' className="btn-custom">
                            <span className="text">Tell your story</span>
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#9d44c0" stroke="none"><path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"></path></g></svg>
                            </span>
                        </Link> */}
                        <div className="d-flex align-items-center share-wrapper">
                            {/* <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${formUrl}`} target={'_blank'} className="share-icon">
                                <FaLinkedinIn />
                            </a>
                            <a href={`https://www.facebook.com/sharer.php?u=${formUrl}`} target={'_blank'} className="share-icon">
                                <FaFacebookF />
                            </a>
                            <a href={`https://twitter.com/intent/tweet?url=${formUrl}&text=Checkout%20this%20amazing%20story&hashtags=UnileverDiaries`} target={'_blank'} className="share-icon">
                                <FaTwitter />
                            </a> */}
                            {/* <a href="#" target={'_blank'} className="share-icon">
                                <FaInstagram />
                            </a> */}
                            <a href={`whatsapp://send/?text=Your%20story%20inspired%20me%2C%20I%27d%20love%20for%20it%20to%20inspire%20others%20too!%20Share%20your%20journey%20here%20${formUrl}`} target={'_blank'} className="share-icon">
                                <FaWhatsapp />
                            </a>
                            <a href={`mailto:?subject=Your%20story%20inspired%20me%2C%20I%27d%20love%20for%20it%20to%20inspire%20others%20too!%20Share%20your%20journey%20here%20&body=${formUrl}`} target={'_blank'} className="share-icon">
                                <FaEnvelope />
                            </a>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </section>
    )
}

export default TellStories