import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom'
import { BsPlayCircle } from 'react-icons/bs'
import { Fade } from 'react-reveal'
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';


const Banner = () => {
  return (
    <section className="banner">
      <div className="banner-slider-wrap">
        <Splide
          className="banner-slider"
          options={{
            type: "loop",
            autoplay: true,
            interval: 5000 , 
            perPage: 1,
            arrows: false,
            pagination: false,
            autoplaySpeed: 5000, 
            speed: 2000,
          }}
        >
          <SplideSlide>
            <div className="banner-item">
              <div className="bg-img">
                <img
                  src="/images/homepage/Khushboo5.jpg"
                  alt="Banner"
                />
              </div>
              <div className="banner-text">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                          <Fade bottom>
                            <span className="d-block banner-heading">Power of U</span>
                            <h2>Showcasing the humans of HUL, one story at a time
                              <span className="d-block">
                                
                                {/* <Typed
                                  strings={['Opportunities.', 'Unbreakable bonds.', 'Moments.', 'Innovation.', 'Passion.']}
                                  typeSpeed={70}
                                  backSpeed={30}
                                  loop={true}
                                  /> */}
                              </span>
                            </h2>
                            {/* <p>
                            one story at a time
                            </p> */}
                            <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mt-5">
                                <Link to={`/you`} className='btn btn-custom'>
                                    <span className='text'> Share your story</span>
                                    <span className="icon">
                                        <svg
                                        version="1.0"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="96.000000pt"
                                        height="96.000000pt"
                                        viewBox="0 0 96.000000 96.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        >
                                        <g
                                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                            fill="#005eff"
                                            stroke="none"
                                        >
                                            <path
                                            d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                                            />
                                        </g>
                                        </svg>
                                    </span>
                                </Link>
                                {/* <button className='btn watch-intro mt-3 mt-lg-0 ms-lg-4 d-flex align-items-center'>
                                    <span className="text">Watch Intro</span>
                                    <BsPlayCircle />
                                </button> */}
                            </div>
                          </Fade>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="banner-item">
              <div className="bg-img">
                <img
                  src="/images/homepage/homepage-top-banner-image-2.jpg"
                  alt="Banner"
                />
              </div>
              <div className="banner-text">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                          <Fade bottom>
                            <span className="d-block banner-heading">Power of U</span>
                            <h2>A universe of 
                              <span className="d-block">
                                <Typed
                                  strings={['Opportunities.', 'Unbreakable bonds.', 'Moments.', 'Innovation.', 'Passion.']}
                                  typeSpeed={70}
                                  backSpeed={30}
                                  loop={true}
                                  />
                              </span>
                            </h2>
                            <p>
                              People with purpose thrive.
                            </p>
                            <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mt-5">
                                <Link to={`/you`} className='btn btn-custom'>
                                    <span className='text'> Share your story</span>
                                    <span className="icon">
                                        <svg
                                        version="1.0"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="96.000000pt"
                                        height="96.000000pt"
                                        viewBox="0 0 96.000000 96.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        >
                                        <g
                                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                            fill="#005eff"
                                            stroke="none"
                                        >
                                            <path
                                            d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                                            />
                                        </g>
                                        </svg>
                                    </span>
                                </Link>
                                {/* <button className='btn watch-intro mt-3 mt-lg-0 ms-lg-4 d-flex align-items-center'>
                                    <span className="text">Watch Intro</span>
                                    <BsPlayCircle />
                                </button> */}
                            </div>
                          </Fade>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div className="scroll-down">
          <a href="#latestStories"> <span></span> Scroll Down</a>
      </div>
    </section>
  );
}

export default Banner