import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import UserRegister from '../components/UserRegister.js'
import AppContext from '../context/AppContext'
import MetaData from '../helpers/MetaData'

const Register = ({ setSideMenu, sideMenu }) => {
  const {user} = useContext(AppContext);
  const navigate = useNavigate()

  useEffect(() => {
    if(user) {
      navigate('/',{replace:true})
    }
  },[user])
  
  return (
    <>
        <MetaData title='Register | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
        <Header setSideMenu={setSideMenu} />
        <section className="tell-your-story auth-content">
          <div className="container">
            <div className="headings">
              <span className="h4 d-block">Register</span>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
            </div>
            <div className="you-form-wrapper">
              <UserRegister />
            </div>
          </div>
          <img
            src="/images/homepage/sky-blue-icon-2.svg"
            className="top-left"
            alt=""
          />
          <img
            src="/images/homepage/sky-blue-icon-1.svg"
            className="top-right"
            alt=""
          />
        </section>
        <Footer/>
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  )
}

export default Register