import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  FaCheckCircle,
  FaEnvelope,
  FaMicrophone,
  FaPause,
  FaPlay,
  FaSave,
  FaStop,
  FaTimes,
  FaTrash,
  FaWhatsapp,
} from "react-icons/fa";
import Dropzone from "../helpers/Dropzone";
import { useDropzone } from "react-dropzone";
import { Fade, Flip } from "react-reveal";
import { useStopwatch } from "react-timer-hook";
import notification from "../helpers/notification";
import ApiService from "../service/ApiService";
import { Button, Modal } from "react-bootstrap";
import AppContext from "../context/AppContext";

const TellYourStory = () => {
  const {user} = useContext(AppContext)
  const [campusOptions, setCampusOptions] = useState([]);
  const [themeOptions, setThemeOptions] = useState([]);
  const [loading, setLoading] = useState(false)
  const [storyType, setStoryType] = useState('text')
  const [designation, setDesignation] = useState([])
  const [showModal, setShowModal] = useState(false);

  const fetchCampusTheme = async () => {
    try {
      let res = await ApiService.fetchCampusTheme();
      setCampusOptions(
        res.campuses.map((campus) => ({
          value: campus._id,
          label: campus.title,
        }))
      );
      let _themes  = res.themes.filter((th) => th.privateTheme !== 'true')
      setThemeOptions(
        _themes.map((theme) => ({ value: theme._id, label: theme.title }))
      );
    } catch (error) {
      console.log(error);
      notification(
        "Unable to fetch campus and theme. Please check your connection and try again!",
        "error"
      );
    }
  };

  const fetchDesignation = async() => {
    try {

      const res = await ApiService.fetchDesignation()
      setDesignation(
        res.designation.map((des) => ({
          value: des._id,
          label: des.title
        }))
        )

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCampusTheme();
    fetchDesignation();
  }, []);
  

  // Audio Recording
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [recordings, setRecordings] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);

  // Check Permissions
  useEffect(() => {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          if (stream.getAudioTracks().length > 0) {
            //code for when none of the devices are available
            setIsBlocked(false);
          } else {
            setIsBlocked(true);
            // code for when both devices are available
          }
        })
        .catch(function (error) {
          // code for when there is an error
          setIsBlocked(true);
          console.log(error);
        });
      navigator.getUserMedia(
        { audio: true },
        () => {
          // console.log("Access granted.");
          setIsBlocked(false);
        },
        () => {
          // console.log("Access denied.");
          setIsBlocked(true);
        }
      );
    } catch (error) {
      console.log(error);
      setIsBlocked(true);
    }
  }, []);

  const onStop = (audioData) => {
    if (process.env.NODE_ENV === "production") {
      if (hours >= 0 && minutes >= 0 && seconds > 10) {
        setRecordings((prevRecordings) => [...prevRecordings, audioData]);
        reset();
        notification('Recording saved sucessfully!', 'success')
      }else {
        notification('Recording should be more than 10 seconds long', 'error')
      }
    } else {
      setRecordings((prevRecordings) => [...prevRecordings, audioData]);
      reset();
      notification('Recording saved sucessfully!', 'success')
    }
  };
  const startRecording = async () => {
    if(recordings.length >= 3) {
      notification('You\'ve reached the maximum recording limit', 'error')
      return false
    } else {
      if (!isBlocked) {
        setRecordState(RecordState.START);
        start();
        notification("Recording started", "success");
      } else {
        notification(
          "You need to enable permission for the use of microphone to use this feature.",
          "error"
        );
      }
    }
  };
  const resumeRecording = async () => {
    start();
    setRecordState(RecordState.START);
  }
  const stopRecording = () => {
    try {
      setRecordState(RecordState.STOP);
    } catch (error) {
      console.log(error);
    }
  };
  const pauseRecording = () => {
    setRecordState(RecordState.PAUSE);
    pause();
  };
  const deleteRecording = (index) => {
    setRecordings((prevRecordings) =>
      prevRecordings.filter((value, i) => i !== index)
    );
  };
  useState(() => {
    if (recordState === RecordState.START) {
      startRecording();
    }
  }, [recordState]);

  // Timer
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({ autoStart: false });
  useEffect(() => {
    if(minutes >= 15) {
      stopRecording()
      reset()
    }
  }, [minutes])

  // Dropzone starts
  const [files, setFiles] = useState([]);

  const deleteThumb = (index) => {
    setFiles((prevFiles) => prevFiles.filter((value, i) => i !== index));
  };

  const thumbs = files.map((file, i) => (
    <Flip left cascade key={i}>
      <div className="me-2 thumbs">
        <figure className="mb-0 mx-2">
			{
				file.type !== 'video/mp4'
				?
				<img
				  src={file.preview}
				  height={150}
				  style={{ objectFit: "cover" }}
				  alt={file.name}
				/>
				:
				<video
				src={file.preview}
				height={150}
				controls
				>
				</video>

			}
          <button className="btn p-0 btn-danger" type="button" onClick={() => deleteThumb(i)}>
            <FaTimes />
          </button>
        </figure>
      </div>
    </Flip>
  ));

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      notification(
        "Please choose images in the specified format and maximum of 2 images",
        "error"
      );
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 2,
	  maxSize: 10485760,
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
  });

  // Dropzone clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // Steps
  const [step, setStep] = useState(1);

  const next = () => {
    if (step < 4) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prev = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  // FormData

  const [storyData, setStoryData] = useState({
    name: "",
    email: "",
    campus: "",
    designation: "",
    theme: "",
    story: "",
  });

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  const [error, setError] = useState(false)
  const [dzError, setDzError] = useState('')

// Step One Validation
const stepOneNext = () => {
	document.querySelectorAll('input').forEach(item => item.style.borderColor = '#ca63f2')
	document.querySelectorAll('.react-select__control').forEach(item => item.style.borderColor = '#ca63f2')
	let hasError = false
  let emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  setError(false)
	if(storyData.name.trim() === '') {
		document.querySelector('#name').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	if(storyData.email.trim() === '') {
		document.querySelector('#email').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	if(storyData.email.trim() !== '' && !emailReg.test(storyData.email)) {
		document.querySelector('#email').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	if(storyData.campus.trim() === '') {
		document.querySelector('#campus .react-select__control').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	if(storyData.designation.trim() === '') {
		document.querySelector('#designation .react-select__control').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	if(storyData.theme.trim() === '') {
		document.querySelector('#theme .react-select__control').style.borderColor = '#77ddf5'
		hasError = true
    setError(true)
	}
	
	if(hasError){
		return false
	} else {
    setError(false)
		next()
	}
};

// Step Two Validation
const stepTwoNext = () => {
	let hasError = false  
  if((storyData.story.trim() === '') && (!recordings.length)) {
    hasError = true
    setError(true)
  }
	
	if(hasError){
		return false
	} else {
    setError(false)
		next()
	}

}

// const agreeConsentTerms = () => {
//   document.querySelector('#termsAndCondition').checked = true
//   setShowModal(false)
// }
// const disAgreeConsentTerms = () => {
//   document.querySelector('#termsAndCondition').checked = false
//   setShowModal(false)
// }

const handleSubmit = async () => {
  setDzError('')
  if(files.length > 2) {
    setDzError('You cannot upload more than two files.')
    return false
  }
  try {
    setError(false)
    setLoading(true)
    document.querySelector('#consent').style.borderColor = '#ca63f2'
    if(document.querySelector('#consent').checked) {
      let formData = new FormData();
      formData.append("name", storyData.name);
      formData.append("email", storyData.email);
      formData.append("campus", storyData.campus);
      formData.append("theme", storyData.theme);
      formData.append("designation", storyData.designation);
      formData.append("story", storyData.story);
      // formData.append('allowSocialShare', document.querySelector('#termsAndCondition').checked)
      files.forEach((file) => {
      formData.append("images", file);
      });
      recordings.forEach((file) => {
      formData.append("recordings", blobToFile(file.blob, `${file.url}`));
      });

      const res = await ApiService.postStory(formData);
      if (res.status === 'success') {
        notification("Congratulations! Your story has been submitted successfully!", "success");
        next();
        setLoading(false)
      } else {
        notification("Something went wrong! Please try again.", "error");
        setLoading(false)
      }
    } else {
      setLoading(false)
      setError(true)
      document.querySelector('#consent').style.borderColor = '#FFF'
      return false
    }
  } catch (error) {
    // console.log(error.response)
    if(error.response.status === 413) {
      setLoading(false)
      notification('Payload size exceeded the limit. Please remove some audio / media files', 'error')
    } else {
      setLoading(false)
      notification('Something went wrong! Please ensure that you\'ve filled all the required fields and try again.', 'error')
    }
  }
};

useEffect(() => {
  if(user) {
    setStoryData((prevData) => ({
      ...prevData,
      name: user.name,
      email: user.email,
    }))
  }
},[user])

return (
  <>
      <section className="tell-your-story" id="you-form">
        <div className="container">
          <div className="headings">
            <span className="h4 d-block">Your Story</span>
            <p>Can't wait to get to know the real you.</p>
          </div>
          <div className="you-form-wrapper overflow-visible">
            <div className="row">
              <div className="col-lg-4">
                <div className="form-steps">
                  <div className="d-flex flex-lg-column flex-row pt-4 justify-content-between">
                    <Fade>
                      <div className="steps-wrapper">
                        <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                          <div
                            className={`step-icon ${step === 1 && `active`} ${
                              step > 1 && `complete`
                            }`}
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g id="_x23_ffffffff"></g>
                              <g id="_x23_000000ff">
                                <path
                                  className="st0"
                                  d="M234.2,8.6c21.8-3.1,44.7-2.1,65.4,6c27.4,10.2,50.8,30.4,65,55.9c12.6,22.4,17.9,48.7,15,74.2
                                                              c-2.6,24.7-12.9,48.6-29.1,67.4c-18.5,21.8-44.8,36.9-73,41.7c-27.2,4.9-56,0.3-80.4-12.9c-22.8-12.1-41.7-31.7-53.1-54.9
                                                              c-11.1-22.6-15.2-48.5-11.3-73.4c3.8-25.5,15.8-49.8,33.9-68.3C184.6,25.8,208.7,13.1,234.2,8.6z"
                                />
                                <path
                                  className="st0"
                                  d="M199.7,288.7c15.6-2.2,31.3-1.6,47-1.7c14,0,27.9,0,41.9,0c23,0.2,46,4.6,67.4,13.1
                                                              c38.6,15.2,71.7,43.7,92.5,79.5c16.8,28.7,25.7,61.9,25.4,95.1c-0.1,14.3-11.4,27.2-25.4,29.7c-7.5,0.2-22.4,0.7-29.9,1H86.2
                                                              c-10-1.4-20.9,1.3-30.3-3.4c-10.4-4.7-17.6-15.3-18.1-26.6v-4.2c0.5-44.1,17.2-87.8,46.5-120.8C113.7,317,155.4,294.5,199.7,288.7z
                                                              "
                                />
                              </g>
                            </svg>
                          </div>
                          <div
                            className={`step-info ms-0 ms-lg-3 mt-3 mt-lg-0 ${
                              step === 1 && `active`
                            }`}
                          >
                            <span className="d-block text-uppercase">
                              Step 01
                            </span>
                            <p className="mb-0">Personal details</p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade>
                      <div className="steps-wrapper">
                        <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                          <div
                            className={`step-icon after ${
                              step === 2 && `active`
                            } ${step > 2 && `complete`}`}
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g id="_x23_ffffffff"></g>
                              <g id="_x23_000000ff">
                                <path
                                  className="st0"
                                  d="M66.8,63.8C73.2,45.5,91.6,32.2,111,32c69.7-0.1,139.4,0,209,0c0,29.3,0,58.7,0,88c-0.2,8.8,0,18,3.6,26.2
                                                              c6.9,17.3,24.7,29.6,43.4,29.8c27,0.1,54,0,81,0.1c0,82,0,164,0,246c0.1,9,0.3,18.3-3.1,26.8c-6.6,18.4-25.4,31.4-44.9,31.2
                                                              c-96,0-192,0-288,0c-18.5,0.2-36.4-11.4-43.8-28.3c-2.9-6.5-4.3-13.6-4.2-20.6c0-112.7,0-225.3,0-338
                                                              C64.1,83.2,63.2,73.1,66.8,63.8z"
                                />
                                <path
                                  className="st0"
                                  d="M352.1,42.4c13.2,14.7,25.7,29.9,38.7,44.8c16.1,18.9,32.5,37.7,48.5,56.7c-23.4,0.1-46.8,0-70.2,0
                                                              c-8.8,0.6-17.2-7.1-16.9-15.9C352,99.5,351.9,71,352.1,42.4z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div
                            className={`step-info ms-0 ms-lg-3 mt-3 mt-lg-0 ${
                              step === 2 && `active`
                            }`}
                          >
                            <span className="d-block text-uppercase">
                              Step 02
                            </span>
                            <p className="mb-0">Your story</p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade>
                      <div className="steps-wrapper">
                        <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                          <div
                            className={`step-icon after ${
                              step === 3 && `active`
                            } ${step > 3 && `complete`}`}
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g id="_x23_ffffffff"></g>
                              <g id="_x23_010103ff">
                                <path
                                  className="st0"
                                  d="M6.2,48.1C15.6,28.8,36.5,15.8,58,16C189.3,16,320.7,16,452,16c9.3-0.2,18.7,1.6,27.1,5.8
                                                              c19.4,9.1,32.5,29.6,32.8,51v365.8c0,21.6-13.2,42.4-32.8,51.6c-8.1,4-17.2,5.8-26.2,5.8c-130.7,0-261.3,0-392,0
                                                              c-6.3,0-12.7-0.4-18.8-2.2c-16.3-4.5-30.2-16.5-37.1-31.9c-3.4-7.3-4.9-15.3-5.2-23.3V72.6C0.3,64.1,2.3,55.7,6.2,48.1
                                                              M323.5,102.7c-15.3,2-29.6,10.8-38.3,23.5c-8.1,11.5-11.5,26.1-9.4,40c2.2,16,11.9,30.8,25.7,39.3c11.9,7.5,26.8,10.2,40.6,7.4
                                                              c15.7-3,29.7-13.2,37.6-27.1c6.2-10.8,8.6-23.7,6.8-36c-2.1-14.4-10.1-27.9-21.8-36.5C353,104.6,337.9,100.7,323.5,102.7
                                                              M176.5,216.7c-5.8,1.4-9.7,6.5-12.3,11.6c-31.7,62.7-63.3,125.4-95,188.1c-2.9,5.6-4.8,12.9-1.2,18.7c3.5,5.8,10.7,7.5,17,7.4
                                                              c116.3,0,232.7,0,349,0c4.6-0.1,9.5-0.3,13.5-3c3.1-2,5-5.8,4.5-9.6c-0.3-4.6-3-8.7-5.5-12.4c-19.9-28.4-39.8-56.8-59.7-85.2
                                                              c-5-7.3-14-12-22.9-10.1c-6.4,1.1-11.5,5.3-15.8,9.9c-6.8,6.9-13.6,13.8-20.4,20.6c-4.7,4.7-11,7.9-17.7,7.8
                                                              c-7.9,0.1-15.2-4.5-19.8-10.6c-31.8-41.5-63.6-83.1-95.4-124.7C190.6,219.7,183.8,214.7,176.5,216.7z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div
                            className={`step-info ms-0 ms-lg-3 mt-3 mt-lg-0 ${
                              step === 3 && `active`
                            }`}
                          >
                            <span className="d-block text-uppercase">
                              Step 03
                            </span>
                            <p className="mb-0">Upload and submit</p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                    <Fade>
                      <div className="steps-wrapper">
                        <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                          <div
                            className={`step-icon after ${
                              step === 4 && `active`
                            }`}
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g id="_x23_ffffffff"></g>
                              <g id="_x23_000000ff">
                                <path
                                  className="st0"
                                  d="M382.5,0h3.1c19.9,0.4,39.5,7.9,54.4,21c14.7,12.8,24.9,30.7,28,49.9c3.5,20.1-0.5,41.5-11.3,58.8
                                                              c-10,16.4-25.6,29.2-43.6,35.7c-17.6,6.4-37.4,6.8-55.2,0.9c-15.5-5-29.2-14.8-39.6-27.2c-36,16.6-72,33.2-108,49.8
                                                              c2.6,12.4,3.9,25.3,2,37.9c-1.1,7.8-3.2,15.4-5.7,22.9c27.1,16.7,54.1,33.3,81.2,50c12.9-14.3,28.7-26.1,46.5-33.5
                                                              c20.9-9,44.3-12,66.8-9c33.9,4.3,65.6,23.2,85.8,50.8c16.1,21.4,24.7,47.9,25.2,74.6v2.9c-0.5,18.7-4.5,37.5-13,54.3
                                                              c-15.9,33.3-47.1,59-83,68c-9.9,2.8-20.2,3.8-30.5,4.2h-3.1c-23-0.5-45.9-6.8-65.4-19c-31.4-19-53.7-52.2-59.3-88.5
                                                              c-3.9-23-0.9-46.8,7.6-68.4c-27-16.6-54.1-33.3-81.1-49.9c-10.9,11.3-24,20.7-38.8,26.4c-23.3,9.4-49.9,9.8-73.6,1.6
                                                              C51.3,307,33,293.4,20.2,275.7C7.3,258,0.4,236.3,0,214.5v-2.2c0.4-18.4,5.2-36.8,14.6-52.7c10.8-18.7,27.6-33.9,47.2-43
                                                              c30.8-14.5,68.7-12.9,98,4.6c12.8,7.2,23.6,17.5,32.5,29.1c35.6-16.4,71.2-32.8,106.8-49.3c1.7-0.5,0.6-2.4,0.6-3.5
                                                              c-4.6-27.6,6.3-57,27.2-75.5C342,8.2,362.1,0.4,382.5,0z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div
                            className={`step-info ms-0 ms-lg-3 mt-3 mt-lg-0 ${
                              step === 4 && `active`
                            }`}
                          >
                            <span className="d-block text-uppercase">
                              Step 04
                            </span>
                            <p className="mb-0">Share</p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-content h-100">
                  <form action="#" method="POST">
                    {/* Step 01 */}
                    {step === 1 && (
                      <Fade>
                        <div id="step-1" className="d-block">
                          <span className="text-uppercase d-block step">
                            Step 01
                          </span>
                          <p>Let's start with your personal details</p>
                          <div className="form-group">
                            <label htmlFor="name">Name *</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="name"
                              value={storyData.name}
                              readOnly
                              disabled
                              placeholder="Enter your name"
                              // onChange={(e) =>
                              //   setStoryData((prevData) => ({
                              //     ...prevData,
                              //     name: e.target.value,
                              //   }))
                              // }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email *</label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Enter your email"
                              id="email"
                              value={storyData.email}
                              readOnly
                              disabled
                              // onChange={(e) =>
                              //   setStoryData((prevData) => ({
                              //     ...prevData,
                              //     email: e.target.value,
                              //   }))
                              // }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="campus">Base Location *</label>
                            <Select
                              options={campusOptions}
                              isSearchable={true}
                              placeholder={`Choose Base Location`}
                              className={`react-select-container`}
                              classNamePrefix={"react-select"}
                              value={campusOptions.find((op) => {
                                return op.value === storyData.campus;
                              })}
                              id={"campus"}
                              onChange={(e) =>
                                setStoryData((prevData) => ({
                                  ...prevData,
                                  campus: e.value,
                                }))
                              }
                              // menuIsOpen={true}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="designation">I Work In *</label>
                            {/* <input
                              type="text"
                              name="designation"
                              className="form-control"
                              id="designation"
                              value={storyData.designation}
                              onChange={(e) =>
                                setStoryData((prevData) => ({
                                  ...prevData,
                                  designation: e.target.value,
                                }))
                              }
                            /> */}
                            <Select
                              options={designation}
                              isSearchable={true}
                              placeholder={`Choose Department`}
                              className={`react-select-container`}
                              classNamePrefix={"react-select"}
                              value={designation.find((op) => {
                                return op.value === storyData.designation;
                              })}
                              id={"designation"}
                              onChange={(e) =>
                                setStoryData((prevData) => ({
                                  ...prevData,
                                  designation: e.value,
                                }))
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="themes">Themes *</label>
                            <Select
                              options={themeOptions}
                              isSearchable={true}
                              placeholder={`Choose Theme`}
                              className={`react-select-container`}
                              classNamePrefix={"react-select"}
                              // menuIsOpen={true}
                              value={themeOptions.find((op) => {
                                return op.value === storyData.theme;
                              })}
                              onChange={(e) =>
                                setStoryData((prevData) => ({
                                  ...prevData,
                                  theme: e.value,
                                }))
                              }
                              id={"theme"}
                            />
                          </div>
                          <div className="form-group text-end mt-4 next-btn-wrapper">
                            <button
                              type="button"
                              onClick={stepOneNext}
                              className="btn btn-custom"
                            >
                              <span className="text">Next</span>
                              <span className="icon">
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="96.000000pt"
                                  height="96.000000pt"
                                  viewBox="0 0 96.000000 96.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                    fill="#9d44c0"
                                    stroke="none"
                                  >
                                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </div>
                          {
                            error
                            &&
                            <p className="form-error text-end">Please fill in all the mandatory fields.</p>
                          }
                        </div>
                      </Fade>
                    )}
                    {/* Step 02 */}
                    {step === 2 && (
                      <Fade>
                        <div id="step-2">
                          <span className="text-uppercase d-block step">
                            Step 02
                          </span>
                          <p>Share your story by text or audio</p>
                          <div className="d-flex align-items-center justify-content-between story-type-switch">
                            <div className="form-group">
                              <input type="radio" id="text" defaultChecked={storyType === 'text' ? true : false} name="story-type" onChange={() => setStoryType('text')} value={'text'} />
                              <label htmlFor="text"> <span className="radio"></span> Text</label>
                            </div>
                            <div className="form-group">
                              <input type="radio" id="audio" defaultChecked={storyType === 'audio' ? true : false} name="story-type" onChange={() => setStoryType('audio')} value={'audio'} />
                              <label htmlFor="audio"> <span className="radio"></span> Audio</label>
                            </div>
                          </div>
                          {
                            storyType === 'text'
                            ?
                            <div className="form-group">
                              <label htmlFor="story">Write your story</label>
                              <textarea
                                name="story"
                                id="story"
                                className="form-control"
                                cols="30"
                                rows="8"
                                placeholder="Talk about your journey.. Tell us about your achievements.. Or just head over and drop us a voice note talking about you.
                                Don't worry, Use as many words as you like - They are limitless just like YOU!"
                                value={storyData.story}
                                onChange={(e) =>
                                  setStoryData((prevData) => ({
                                    ...prevData,
                                    story: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            :
                            <>
                              <div className="form-group">
                                <label htmlFor="audio">
                                  Record your audio (Recording should be more than 10
                                  seconds long)
                                </label>
                                <div className="record-audio-box">
                                  <AudioReactRecorder
                                    state={recordState}
                                    onStop={onStop}
                                    canvasWidth="0"
                                    canvasHeight="0"
                                  />
                                  {(recordState === RecordState.STOP ||
                                    recordState === RecordState.NONE) && (
                                    <Fade>
                                      <button
                                        className="btn btn-record"
                                        onClick={startRecording}
                                        type="button"
                                      >
                                        <FaMicrophone />
                                      </button>
                                    </Fade>
                                  )}
                                  {(recordState === RecordState.START ||
                                    recordState === RecordState.PAUSE) && (
                                    <Fade>
                                      <button
                                        className="btn btn-pause ms-4"
                                        onClick={
                                          recordState === RecordState.START
                                            ? pauseRecording
                                            : resumeRecording
                                        }
                                        type="button"
                                      >
                                        <span className="me-3">
                                          {hours > 9 ? hours : `0${hours}`}:
                                          {minutes > 9 ? minutes : `0${minutes}`}:
                                          {seconds > 9 ? seconds : `0${seconds}`}
                                        </span>
                                        {recordState === RecordState.START ? (
                                          <FaPause />
                                        ) : (
                                          <FaPlay />
                                        )}
                                      </button>
                                      <button
                                        className="btn btn-save ms-4"
                                        onClick={stopRecording}
                                        type="button"
                                      >
                                        <FaStop />
                                        {/* <FaSave /> */}
                                      </button>
                                    </Fade>
                                  )}
                                </div>
                              </div>
                              <div className="form-group mt-5">
                                <div className="recorded-audio-list">
                                  {recordings.map((recording, i) => (
                                    <div
                                      className="audio-wrapper d-flex align-items-center mb-2"
                                      key={i}
                                    >
                                      <span className="d-block">{i + 1}.</span>
                                      <audio
                                        controls
                                        className="w-100 mx-4"
                                        src={recording.url}
                                      ></audio>
                                      <button
                                        type="button"
                                        onClick={() => deleteRecording(i)}
                                        className="btn p-0"
                                      >
                                        <FaTrash />
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          }
                          <div className="form-group next-btn-wrapper mt-5 d-flex align-items-center justify-content-between">
                            <button
                              type="button"
                              onClick={prev}
                              className="btn btn-custom prev"
                            >
                              <span className="icon ms-0">
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="96.000000pt"
                                  height="96.000000pt"
                                  viewBox="0 0 96.000000 96.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                    fill="#9d44c0"
                                    stroke="none"
                                  >
                                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                  </g>
                                </svg>
                              </span>
                              <span className="text">Previous</span>
                            </button>
                            <button
                              type="button"
                              onClick={stepTwoNext}
                              className="btn btn-custom"
                            >
                              <span className="text">Next</span>
                              <span className="icon">
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="96.000000pt"
                                  height="96.000000pt"
                                  viewBox="0 0 96.000000 96.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                    fill="#9d44c0"
                                    stroke="none"
                                  >
                                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </div>
                          {
                            error
                            &&
                            <p className="form-error text-end">Please share your story either by typing or recording.</p>
                          }
                        </div>
                      </Fade>
                    )}
                    {/* Step 03 */}
                    {step === 3 && (
                      <Fade>
                        <div id="step-3">
                          <span className="text-uppercase d-block step">
                            Step 03
                          </span>
                          <p>Upload your images and submit your story</p>
                          <div className="form-group">
                            <label htmlFor="images">
                              Drop two images that are most relatable to your story (Not mandatory)
                            </label>
                            <Dropzone
                              isDragActive={isDragActive}
                              isDragAccept={isDragAccept}
                              isDragReject={isDragReject}
                              getRootProps={getRootProps}
                              getInputProps={getInputProps}
                              thumbs={thumbs}
                            />
                          </div>
                          {
                            (dzError && dzError.length)
                            &&
                            <p className="form-error text-end">{ dzError }</p>
                          }
                          <div className="form-group d-flex align-items-start mt-5">
                            <input
                              className="form-check-input mt-1"
                              type="checkbox"
                              name="consent"
                              value="true"
                              id="consent"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="consent"
                            >
                              I have read and understood the <a className="text-decoration-none" href="/images/common/global-employee-privacy-notice.pdf" target="_blank">Employee Privacy Notice</a>. I consent to Unilever processing my personal information and publishing my story on Unilever official platforms, including our Partner platforms.
                            </label>
                          </div>
                          {/* <div className="form-group d-flex align-items-start mt-5" style={{cursor: 'pointer'}} onClick={() => setShowModal(true)}>
                            <div className="d-flex align-items-start" style={{pointerEvents: 'none'}}>
                              <input
                                className="form-check-input mt-1"
                                type="checkbox"
                                name="termsAndCondition"
                                value="true"
                                id="termsAndCondition"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="termsAndCondition"
                              >
                                I consent Unilever and Humans Of Bombay to publish my story on their social media handle.
                              </label>
                            </div>
                          </div> */}
                          <div className="form-group next-btn-wrapper mt-5 d-flex align-items-center justify-content-between">
                            <button
                              type="button"
                              onClick={prev}
                              className="btn btn-custom prev"
                            >
                              <span className="icon ms-0">
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="96.000000pt"
                                  height="96.000000pt"
                                  viewBox="0 0 96.000000 96.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                    fill="#9d44c0"
                                    stroke="none"
                                  >
                                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                  </g>
                                </svg>
                              </span>
                              <span className="text">Previous</span>
                            </button>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-custom"
                            >
                              <span className="text">Submit</span>
                              <span className="icon">
                  {
                    loading
                    ?
                    <svg
                      style={{ margin: 'auto', background: 'transparent', display: 'block', shapeRendering: 'auto' }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      stroke="#9d44c0"
                      strokeWidth="20"
                      r="34"
                      strokeDasharray="160.22122533307947 55.40707511102649"
                      >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="0.5681818181818182s"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                      ></animateTransform>
                      </circle>
                    </svg>
                    :
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96.000000pt"
                      height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                      fill="#9d44c0"
                      stroke="none"
                      >
                      <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                      </g>
                    </svg>
                  }
                              </span>
                            </button>
                          </div>
                          {
                            error
                            &&
                            <p className="form-error text-end">Please agree to the <a href="#">Terms &amp; Conditions</a> before submitting.</p>
                          }
                        </div>
                      </Fade>
                    )}
                    {/* Step 04 */}
                    {step === 4 && (
                      <Fade>
                        <div id="step-4">
                          <span className="text-uppercase d-block step">
                            Step 04
                          </span>
                          <p>Share the page</p>
                          <div className="share-box text-center">
                            <div className="row">
                              <div className="col-lg-10 mx-auto">
                                <FaCheckCircle />
                                <p className="thank-you">
                                  Thank you for sharing your story.
                                </p>
                                <p className="message">
                                  We will review your story, once the management
                                  team approves it. We will publish your story on
                                  the portal.
                                </p>
                                <p className="share">
                                  Share this page with your friends
                                </p>
                                <div className="share-btn-wrapper p-2 d-flex align-items-center justify-content-between">
                                  <a
                                    href={`whatsapp://send?text=${process.env.REACT_APP_PUBLIC_URL}/you`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-btn whatsapp"
                                  >
                                    <FaWhatsapp />
                                  </a>
                                  <a
                                    href={`mailto:?subject=Share your amazing story here&body=Checkout the website: ${process.env.REACT_APP_PUBLIC_URL}/you`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-btn email"
                                  >
                                    <FaEnvelope />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="button-wrapper text-center mt-5">
                            <Link
                              to={`/`}
                              className="btn btn-custom thank-you-btn"
                            >
                              <span className="text">View stories</span>
                              <span className="icon">
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="96.000000pt"
                                  height="96.000000pt"
                                  viewBox="0 0 96.000000 96.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                    fill="#9d44c0"
                                    stroke="none"
                                  >
                                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </Fade>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal className="consent-modal" show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="consent-modal-wrap">
            <h4>Dear {storyData.name},</h4>
            <p>
              Thank you for choosing to share your story on Unilever and Humans of bombay official media handles. Kindly acknowledge the same below by confirming your choice:
            </p>
            <p className="primary">
              "I {storyData.name} consent to share my Unilever Diaries story to be published on Humans of Bombay social media handle."
            </p>
            <button className="me-2" onClick={agreeConsentTerms}>I AGREE</button>
            <button className="grey" onClick={disAgreeConsentTerms}>I DISAGREE</button>
            <p className="mb-0 mt-4">
              Thanks, <br />HOB & Employer Brand Team, HUL
            </p>
          </div>
        </Modal.Body>
      </Modal> */}
  </>
  );
};

export default TellYourStory;
