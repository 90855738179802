import React, { useMemo } from "react";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#ab52ce",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  height: "150px",
  justifyContent: "center",
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Dropzone = ({
  isDragActive,
  isDragAccept,
  isDragReject,
  getRootProps,
  getInputProps,
  thumbs,
}) => {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      <div className="custom-dropzone" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div style={{ color: "#FFF", fontSize: "1.2rem" }}>
          Drag and drop or browse your files
        </div>
        <small>Supports: JPG, JPEG, PNG. Max File Size should be 10Mb</small>
      </div>

    {
      thumbs.length
      ?
      <div className="mt-4">
        <label htmlFor="">Uploaded files</label>
        <div
          style={{
            padding: "10px",
            border: "1px solid #ca63f2",
            backgroundColor: "#ab52ce",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className="preview-img"
        >
          {thumbs}
        </div>
      </div>
      :
      <></>
    }
    </div>
  );
};

export default Dropzone;
