import React, { createContext, useEffect, useState } from "react";
import ApiService from "../service/ApiService";
import notification from "../helpers/notification";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const { user } = await ApiService.fetchUser();
      setUser(user);
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setUser(null);
      notification("Something went wrong!", "error");
    }
  };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      setUser(null);
    }
  }, [token]);

  let values = {
    token,
    setToken,
    user,
    setUser,
    fetchUser,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContext;
