import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import ApiService from "../service/ApiService";

const ResetPassword = ({ setSideMenu, sideMenu }) => {
  const { link } = useParams();
    const navigate = useNavigate()

  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleResetPassword = async (e) => {
    try {
      e.preventDefault();
      const { message } = await ApiService.resetPassword({
        data: resetPasswordDetails,
        token: link,
      });
      notification(message, "success");
      navigate('/login')
    } catch (err) {
      console.log(err);
      notification(err.response.data.message, "error");
    }
  };

  return (
    <> 
      <MetaData title='Reset Password | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
      <Header setSideMenu={setSideMenu} />
      <main>
        <section className="tell-your-story auth-content">
          <div className="container">
            <div className="headings">
              <span className="h4 d-block">Reset Password</span>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
            </div>
            <div className="you-form-wrapper">
              <div className="form-content auth">
                <form onSubmit={handleResetPassword}>
                  <div className="form-group">
                    <label htmlFor="password">Password *</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="password"
                      required
                      placeholder="Password"
                      value={resetPasswordDetails.password}
                      onChange={(e) =>
                        setResetPasswordDetails((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password *</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      id="confirmPassword"
                      required
                      placeholder="Confirm Password"
                      value={resetPasswordDetails.confirmPassword}
                      onChange={(e) =>
                        setResetPasswordDetails((prev) => ({
                          ...prev,
                          confirmPassword: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="form-group mt-4 next-btn-wrapper">
                    <button type="submit" className="btn btn-custom">
                      <span className="text">Change password</span>
                      <span className="icon">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="96.000000pt"
                          height="96.000000pt"
                          viewBox="0 0 96.000000 96.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                            fill="#9d44c0"
                            stroke="none"
                          >
                            <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <img
            src="/images/homepage/sky-blue-icon-2.svg"
            className="top-left"
            alt=""
          />
          <img
            src="/images/homepage/sky-blue-icon-1.svg"
            className="top-right"
            alt=""
          />
        </section>
      </main>
      <Footer />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default ResetPassword;
