import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'react-router-dom'
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'


function Themes({ themeRef }) {

    const [themes, setThemes] = useState([])

    const fetchThemes = async () => {
        try {
            let res = await ApiService.fetchCampusTheme()
            setThemes(res.themes)
        } catch (error) {
            console.log('error')
            notification('Something went wrong! Please try refreshing the page.')
        }
    }

    useEffect(() => {
        fetchThemes()
    }, [])

  return (
    <section className='themes' id='themes' ref={themeRef}>
      <div className='container'>
      <Fade bottom>
        <div className='headings-2'>
            <h4>
                Uniquely Unilever.
            </h4>
            <p>
              {/* Process to share their stories */}
            </p>
        </div>
      </Fade>   
      <div className='theme-cards'>
          <div className='row'>
            {
                themes.map(theme => (
                    <div className='col-lg-3 col-md-6 pe-md-4 pe-auto mb-4' key={theme._id}> 
                        <Fade bottom>
                            <Link to={`/stories/${theme.slug}`} className='theme-card d-block'>
                                <figure className='theme-img'>
                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/themes/${theme.image}`} alt={theme.title} />
                                    <p className='img-text'>{ theme.title }</p>
                                </figure>
                            </Link>
                        </Fade>
                    </div>
                ))
            }
          </div>
      </div>    
      </div>
    </section>
  )
}

export default Themes