import React from 'react'
import Achievements from '../components/Achievements'
import Banner from '../components/Banner'
import FAQCTA from '../components/FAQCTA'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HowItWorks from '../components/HowItWorks'
import LatestStories from '../components/LatestStories'
import ShareYourStory from '../components/ShareYourStory'
import SideMenu from '../components/SideMenu'
import MetaData from '../helpers/MetaData'

const Home = ({ setSideMenu, sideMenu }) => {

  return (
    <>
    <MetaData title='Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
    <main>
      <Header setSideMenu={setSideMenu} />
      <Banner />
      <LatestStories />
      <HowItWorks />
      <ShareYourStory />
      <FAQCTA />
      <Achievements />
      <Footer />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
    
    </>
  )
}

export default Home