import React from 'react'
import { Link } from 'react-router-dom'

const ThemeCard = ({ image, title, status, slug }) => {
  return (
    <Link to={`/stories/${slug}`} className={`theme-card ${status}`} style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/uploads/themes/${image})` }}>
        <span className="h3 d-block">{ title }</span>
    </Link>
  )
}

export default ThemeCard