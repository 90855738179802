import React from 'react'
import { Link } from 'react-router-dom'

const FAQCTA = () => {
  return (
    <section className="py-5 faq-cta">
        <div className="container">
            <div className="row">
                <div className="col-12 py-5">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="h5 text-center">Need more Information?</div>
                        <Link to={`/faqs`} className="btn btn-custom mt-4">
                            <span className="text">View FAQs</span>
                            <span className="icon">
                            <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="96.000000pt"
                                height="96.000000pt"
                                viewBox="0 0 96.000000 96.000000"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <g
                                transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                fill="#7706bc"
                                stroke="none"
                                >
                                <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                </g>
                            </svg>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default FAQCTA