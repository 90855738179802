import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import FeedCard from './FeedCard';


const InspiringStories = ({ feeds }) => {

  return (
    <section className="inspiring-stories">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="headings text-center">
                        <span className="h4 text-white">Read more inspiring stories!</span>
                        <p className="text-white mt-3">Discover more stories and write your own to help inspire others.</p>
                    </div>
                </div>
            </div>
            {
                feeds
                &&
                <div className="stories-slider">
                    <Splide options={{
                        arrows: false,
                        loop: false, 
                        gap: 30, 
                        type:'single',
                        perPage: 3,
                        autoplay: true,
                        interval: 4000,
                        breakpoints: {
                            991: {
                                perPage: 2
                            },
                            576: {
                                perPage: 1,
                            },
                        },
                        }}>
                            {
                                feeds.map(feed => (
                                    <SplideSlide key={feed._id} >
                                        <FeedCard feed={feed} />
                                    </SplideSlide>
                                ))
                            }
                    </Splide>
                </div>
            }
        </div>
    </section>
  )
}

export default InspiringStories