import moment from 'moment'
import { Link } from 'react-router-dom'

const StoryDetailsHeader = ({ story }) => {
  return (
    <section className="story-details-header-wrap pt-5 pb-md-4 pb-3 d-none">
        <div className="container">
        <div className="story-detail-header">
            <div className="story-profile">
            <figure className="mb-0">
                {
                    story.thumbnail
                    ?
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${story.thumbnail}`} alt={story.name} />
                    :
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${story.images[0]}`} alt={story.name} />
                }
            </figure>
            <div className="profile-text">
                <h4>{ story.name }</h4>
                <span>{ story.designation?.title }<span className="ms-4 highlight">{ story.campus.title }</span></span>
            </div>
            </div>
            <div className="story-stats">
            <div className="published-link">
                <Link to={`/stories/${story.theme.slug}`}>{ story.theme.title }</Link>
            </div>
            <div className="published-on">
                <p>
                Published On
                <span className="d-block">{ moment(story.createdAt).format('MMMM DD, YYYY') }</span>
                </p>
            </div>
            </div>
        </div>
        </div>
    </section>
  )
}

export default StoryDetailsHeader