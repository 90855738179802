import ReactHlsPlayer from "react-hls-player";

const HlsPlayer = ({url, poster}) => {
  return (
    <ReactHlsPlayer
        poster={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/posters/${poster}`}
        src={url}
        autoPlay={false}
        controls={true}
        width="100%"
        height="100%"
      />
  )
}

export default HlsPlayer