import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <section className="not-found py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="headings text-left">
              <span className="h1 d-block">
                We're sorry, that page cannot be found
              </span>
              <p>
                The page might have been removed, had its name changed or is
                temporarily unavailable.
              </p>
            </div>
            <Link to={`/`} className="btn btn-custom">
              <span className="icon">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="96.000000pt"
                  height="96.000000pt"
                  viewBox="0 0 96.000000 96.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                    fill="#7705bc"
                    stroke="none"
                  >
                    <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                  </g>
                </svg>
              </span>
              <span className="text">Go Back</span>
            </Link>
          </div>
          <div className="col-lg-6">
            <figure>
              <img src="/images/404/not-found.png" className="w-100" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound