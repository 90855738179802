import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom'
import { BsPlayCircle } from 'react-icons/bs'
import { Fade } from 'react-reveal'
import Typed from 'react-typed';

const BannerCarousel = ({ themeRef }) => {



  return (
    <section className="banner stories-banner">
      <div className="banner-slider-wrap">
        <Splide
          className="banner-slider stories-banner-slider"
          options={{
            // type: "loop",
            perPage: 1,
            arrows: false,
            pagination: false,
            height: '820px',
            autoplay: true,
            interval: 4000,
            paginationDirection:'ltr',
            direction:'ttb',
            breakpoints: {
              0:{
                height:'600px'
              },
              576: {
                direction:'ltr',
              },
              768: {
                height:'600px'
              },
            }
          }}
        >
          <SplideSlide>
            <div className="banner-item">
              <div className="bg-img">
                <img
                  src="/images/stories/story-banner.jpg"
                  alt="Banner"
                />
              </div>
              <div className="banner-text">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                          <Fade bottom>
                            <span className="d-block banner-heading">Power of U</span>
                            <h2>Better
                            <span className="d-block">
                                <Typed
                                  strings={['World.', 'Business.', 'You.']}
                                  typeSpeed={70}
                                  backSpeed={30}
                                  loop={true}
                                  />
                              </span>
                            </h2>
                            <p>
                              Share your story because you never know who you <span className="d-lg-block"> would end up inspiring.</span>
                            </p>
                            <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mt-5">
                                <button onClick={() => themeRef.current.scrollIntoView()} className='btn btn-custom'>
                                    <span className='text'>View stories</span>
                                    <span className="icon">
                                        <svg
                                        version="1.0"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="96.000000pt"
                                        height="96.000000pt"
                                        viewBox="0 0 96.000000 96.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        >
                                        <g
                                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                            fill="#005eff"
                                            stroke="none"
                                        >
                                            <path
                                            d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                                            />
                                        </g>
                                        </svg>
                                    </span>
                                </button>
                                {/* <button className='btn watch-intro mt-3 mt-lg-0 ms-lg-4 d-flex align-items-center'>
                                    <span className="text">Watch Intro</span>
                                    <BsPlayCircle />
                                </button> */}
                            </div>
                          </Fade>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div className="scroll-down">
          <a href="#themes"> <span></span> Scroll Down</a>
      </div>
    </section>
  );
}

export default BannerCarousel