import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MetaData from "../helpers/MetaData";
import ApiService from "../service/ApiService";

const ConsentForm = ({ setSideMenu, sideMenu }) => {
  const { user } = useParams();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  let story = searchParams.get("story");
  const [userData, setUserData] = useState(null);
  const [agreed, setAgreed] = useState(false)
  const [disAgreed, setDisAgreed] = useState(false)

  const fetchUserById = async () => {
    try {
      const {starUser, alreadyAgreed} = await ApiService.fetchUserById({id:user, story});
      if(alreadyAgreed) {
        navigate('/')
      }
      setUserData(starUser)

    } catch (error) {
      console.log(error);
      navigate('/404')
    }
  };

  const handleConsentForm = async (accept) => {
    try {
        const res = await ApiService.agreeConsentForm({
            user,story,status:accept
        })
        console.log(res)
        if(accept) {
            setAgreed(true)
        }else {
            setDisAgreed(true)
        }
    } catch (error) {
        console.log(error)
    }
  };

  useEffect(() => {
    if (story) {
        fetchUserById();
    }
  }, [story]);

  return (
    <>
      <MetaData
        title="Consent Form | Unilever Diaries"
        description="Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries"
      />
      <Header setSideMenu={setSideMenu} />
      <main>
        <section className="consent-form-wrap auth-content">
          <div className="container">
            
            {
                userData ?                    
                    <div className="row">
                        {
                            !disAgreed && !agreed && 
                            <>
                            <div className="col-lg-10 mx-auto">
                                <div className="headings mb-4">
                                    <span className="h4 d-block">Consent Form</span>
                                </div>
                            </div>
                            <div className="mx-auto col-lg-10">
                                <div className="consent-modal-wrap">
                                <h4>Dear {userData.name},</h4>
                                <p>
                                    Thank you for choosing to share your story on Unilever and
                                    Humans of bombay official media handles. Kindly acknowledge
                                    the same below by confirming your choice:
                                </p>
                                <p className="primary">
                                    "I {userData.name} consent to share my Unilever Diaries story
                                    to be published on Humans of Bombay social media handle."
                                </p>
                                <button className="me-2" onClick={() => handleConsentForm(true)}>
                                    I AGREE
                                </button>
                                <button className="grey" onClick={() => handleConsentForm(false)}>
                                    I DISAGREE
                                </button>
                                <p className="mb-0 mt-4">
                                    Thanks, <br />
                                    HOB & Employer Brand Team, HUL
                                </p>
                                </div>
                            </div>
                            </>
                        }
                        {
                            agreed && 
                            <>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="headings mb-4">
                                        <span className="h4 d-block">Thank You</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="consent-modal-wrap">
                                <h4>Dear {userData.name},</h4>
                                <p>
                                Thank you for sharing your consent to publish your story on Unilever and Humans of Bombay official social media handles.
                                </p>
                                </div>
                            </div>
                            </>
                        }
                        {
                            disAgreed && 
                            <>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="headings mb-4">
                                        <span className="h4 d-block">Thank You</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="consent-modal-wrap">
                                <h4>Dear {userData.name},</h4>
                                <p>
                                    Thank you for sharing your response.
                                </p>
                                </div>
                            </div>
                            </>
                        }
                    </div> : ''
            }
          </div>
        </section>
      </main>
      <Footer sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default ConsentForm;
