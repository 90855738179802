import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import ApiService from "../service/ApiService";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { useStopwatch } from "react-timer-hook";
import Dropzone from "../helpers/Dropzone";
import { useDropzone } from "react-dropzone";
import { Fade, Flip } from "react-reveal";
import {
  FaTimes,
  FaMicrophone,
  FaPause,
  FaPlay,
  FaTrash,
  FaStop,
} from "react-icons/fa";
import Select from "react-select";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const EditStory = ({ setSideMenu, sideMenu }) => {
  const {slug} = useParams();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  let _storyType = searchParams.get("type");
  const [campusOptions, setCampusOptions] = useState([]);
  const [themeOptions, setThemeOptions] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [storyType, setStoryType] = useState(_storyType);
  const [story, setStory] = useState(null)
  const [step, setStep] = useState(1);
  const [storyData, setStoryData] = useState({
    name: "",
    email: "",
    campus: "",
    designation: "",
    theme: "",
    story: "",
  });
  const [error, setError] = useState(false);
  const [dzError, setDzError] = useState("");
  const [files, setFiles] = useState([]);

  const fetchStoryBySlug = async () => {
    try {
      let {story} = await ApiService.fetchStoryBySlug(slug);
      if(!story) {
        navigate('/my-stories')
      }
      setStory(story)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCampusTheme = async () => {
    try {
      let res = await ApiService.fetchCampusTheme();
      setCampusOptions(
        res.campuses.map((campus) => ({
          value: campus._id,
          label: campus.title,
        }))
      );
      let _themes = res.themes.filter((th) => th.privateTheme !== "true");
      setThemeOptions(
        _themes.map((theme) => ({ value: theme._id, label: theme.title }))
      );
    } catch (error) {
      console.log(error);
      notification(
        "Unable to fetch campus and theme. Please check your connection and try again!",
        "error"
      );
    }
  };

  const fetchDesignation = async () => {
    try {
      const res = await ApiService.fetchDesignation();
      setDesignation(
        res.designation.map((des) => ({
          value: des._id,
          label: des.title,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const checkPermission = async () => {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          if (stream.getAudioTracks().length > 0) {
            //code for when none of the devices are available
            setIsBlocked(false);
          } else {
            setIsBlocked(true);
            // code for when both devices are available
          }
        })
        .catch(function (error) {
          // code for when there is an error
          setIsBlocked(true);
          console.log(error);
        });
      navigator.getUserMedia(
        { audio: true },
        () => {
          // console.log("Access granted.");
          setIsBlocked(false);
        },
        () => {
          // console.log("Access denied.");
          setIsBlocked(true);
        }
      );
    } catch (err) {
      console.log(err);
      setIsBlocked(true);
    }
  };

  const onStop = (audioData) => {
    if (process.env.NODE_ENV === "production") {
      if (hours >= 0 && minutes >= 0 && seconds > 10) {
        setRecordings((prevRecordings) => [...prevRecordings, audioData]);
        reset();
        notification("Recording saved sucessfully!", "success");
      } else {
        notification("Recording should be more than 10 seconds long", "error");
      }
    } else {
      setRecordings((prevRecordings) => [...prevRecordings, audioData]);
      reset();
      notification("Recording saved sucessfully!", "success");
    }
  };

  const startRecording = async () => {
    if (recordings.length >= 3) {
      notification("You've reached the maximum recording limit", "error");
      return false;
    } else {
      if (!isBlocked) {
        setRecordState(RecordState.START);
        start();
        notification("Recording started", "success");
      } else {
        notification(
          "You need to enable permission for the use of microphone to use this feature.",
          "error"
        );
      }
    }
  };

  const resumeRecording = async () => {
    start();
    setRecordState(RecordState.START);
  };

  const stopRecording = () => {
    try {
      setRecordState(RecordState.STOP);
    } catch (error) {
      console.log(error);
    }
  };

  const pauseRecording = () => {
    setRecordState(RecordState.PAUSE);
    pause();
  };

  const deleteRecording = (index) => {
    setRecordings((prevRecordings) =>
      prevRecordings.filter((value, i) => i !== index)
    );
  };

  const deleteStoryRecording = async (recording) => {
    try {
      
      const res = await ApiService.deleteStoryRecording(slug, recording);
      notification(res, "success");
      fetchStoryBySlug()

    } catch (error) {
      console.log(error)      
    }
  }

  const deleteThumb = async (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((value, i) => i !== index));
    try {
      if(file.size === 0) {
       await ApiService.deleteStoryImg(slug, file.name)
      }
    } catch (error) {
      console.log(error)      
    }
  };

  const thumbs = files.map((file, i) => (
    <Flip left cascade key={i}>
      <div className="me-2 thumbs">
        <figure className="mb-0 mx-2">
          {file.type !== "video/mp4" ? (
            <img
              src={file.preview}
              height={150}
              style={{ objectFit: "cover" }}
              alt={file.name}
            />
          ) : (
            <video src={file.preview} height={150} controls></video>
          )}
          <button
            className="btn p-0 btn-danger"
            type="button"
            onClick={() => deleteThumb(file, i)}
          >
            <FaTimes />
          </button>
        </figure>
      </div>
    </Flip>
  ));

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      notification(
        "Please choose images in the specified format and maximum of 2 images",
        "error"
      );
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 2,
    maxSize: 10485760,
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
  });


  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  const handleSubmit = async () => {
    setDzError('')
    if(files.length > 2) {
      setDzError('You cannot upload more than two files.')
      return false
    }
    try {
      setError(false)
      setLoading(true)

      let formData = new FormData();
      formData.append("name", storyData.name);
      formData.append("email", storyData.email);
      formData.append("campus", storyData.campus);
      formData.append("theme", storyData.theme);
      formData.append("designation", storyData.designation);
      formData.append("story", storyData.story);

      if(files.length > 0) {
        files.forEach((file) => {
          if(file.size !== 0) {
            formData.append("images", file);
          }
        });
      }

      recordings.forEach((file) => {
        formData.append("recordings", blobToFile(file.blob, `${file.url}`));
      });

      const {status} = await ApiService.updateStory({formData, id: story._id});
      if(status==='success') {
        notification("Story updated successfully.",'success')
        setLoading(false)
        fetchStoryBySlug()
        setTimeout(() => {
          navigate('/my-stories')
        },1500)
      }else {
        notification("Something went wrong! Please try again",'error')
        setLoading(false)
        setError(true)
        return false
      }
    } catch (error) {
      if(error.response.status === 413) {
        setLoading(false)
        notification('Payload size exceeded the limit. Please remove some audio / media files', 'error')
      } else {
        setLoading(false)
        notification('Something went wrong! Please ensure that you\'ve filled all the required fields and try again.', 'error')
      }
    }
  }

  useEffect(() => {
    fetchCampusTheme();
    fetchDesignation();
    checkPermission();
    fetchStoryBySlug()
  }, []);

  useState(() => {
    if (recordState === RecordState.START) {
      startRecording();
    }
  }, [recordState]);

  // Timer
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    if (minutes >= 15) {
      stopRecording();
      reset();
    }
  }, [minutes]);

  // Dropzone clean up
  useEffect(() => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },[files]);

    useEffect(() => {
        if(story) {
            //set form values 
            setStoryData((prevData) => ({
                ...prevData,
                name: story.name,
                email: story.email,
                campus: story.campus._id,
                designation: story.designation._id,
                theme: story.theme._id,
                story: story.story
              }))
              
              // set dropzone image preview
              let imgPrevArr = []
              story.images.map(img => {
                let _file = new File([""], img, {
                  lastModified: Date.now(),
                  lastModifiedDate: new Date(story.createdAt),
                  type: `image/${img.split('.').at(-1)}`,
                })
                _file.path = img;
                _file.preview = `${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${img}`;
                imgPrevArr.push(_file)
              })

              if (imgPrevArr.length > 0) {
                setFiles(
                  imgPrevArr.map((file) =>
                    Object.assign(file)
                  )
                );
              }
            }
          },[story])

  return (
    <>
      <MetaData
        title="Edit Story | Unilever Diaries"
        description="Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries"
      />
      <Header setSideMenu={setSideMenu} />
      <main>
        <section className="edit-story tell-your-story">
          <div className="container">
            <div className="headings mb-5">
              <span className="h4 d-block">Edit Story</span>
            </div>
            <div className="you-form-wrapper">
              <div className="form-content">
                <form action="#" method="POST">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-white mb-4">Personal Details</h5>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="name">Name *</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          value={storyData.name}
                          readOnly
                          disabled
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="name">Email *</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          id="email"
                          value={storyData.email}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">Base Location *</label>
                        <Select
                          options={campusOptions}
                          isSearchable={true}
                          placeholder={`Choose Base Location`}
                          className={`react-select-container`}
                          classNamePrefix={"react-select"}
                          value={campusOptions.find((op) => {
                            return op.value === storyData.campus;
                          })}
                          id={"campus"}
                          onChange={(e) =>
                            setStoryData((prevData) => ({
                              ...prevData,
                              campus: e.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">I Work In *</label>
                        <Select
                          options={designation}
                          isSearchable={true}
                          placeholder={`Choose Department`}
                          className={`react-select-container`}
                          classNamePrefix={"react-select"}
                          value={designation.find((op) => {
                            return op.value === storyData.designation;
                          })}
                          id={"designation"}
                          onChange={(e) =>
                            setStoryData((prevData) => ({
                              ...prevData,
                              designation: e.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">Themes *</label>
                        <Select
                          options={themeOptions}
                          isSearchable={true}
                          placeholder={`Choose Theme`}
                          className={`react-select-container`}
                          classNamePrefix={"react-select"}
                          // menuIsOpen={true}
                          value={themeOptions.find((op) => {
                            return op.value === storyData.theme;
                          })}
                          onChange={(e) =>
                            setStoryData((prevData) => ({
                              ...prevData,
                              theme: e.value,
                            }))
                          }
                          id={"theme"}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <h5 className="text-white mb-4">Story Details</h5>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-between story-type-switch">
                        <div className="form-group">
                          <input
                            type="radio"
                            id="text"
                            defaultChecked={
                              storyType === "text" ? true : false
                            }
                            name="story-type"
                            onChange={() => setStoryType("text")}
                            value={"text"}
                          />
                          <label htmlFor="text">
                            {" "}
                            <span className="radio"></span> Text
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="radio"
                            id="audio"
                            defaultChecked={
                              storyType === "audio" ? true : false
                            }
                            name="story-type"
                            onChange={() => setStoryType("audio")}
                            value={"audio"}
                          />
                          <label htmlFor="audio">
                            {" "}
                            <span className="radio"></span> Audio
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {storyType === "text" ? (
                        <div className="form-group">
                          <label htmlFor="story">Write your story</label>
                          <textarea
                            name="story"
                            id="story"
                            className="form-control"
                            cols="30"
                            rows="8"
                            placeholder="Talk about your journey.. Tell us about your achievements.. Or just head over and drop us a voice note talking about you.
                                Don't worry, Use as many words as you like - They are limitless just like YOU!"
                            value={storyData.story}
                            onChange={(e) =>
                              setStoryData((prevData) => ({
                                ...prevData,
                                story: e.target.value,
                              }))
                            }
                          ></textarea>
                        </div>
                      ) : (
                        <>
                          <div className="form-group">
                            <label htmlFor="audio">
                              Record your audio (Recording should be more than
                              10 seconds long)
                            </label>
                            <div className="record-audio-box">
                              <AudioReactRecorder
                                state={recordState}
                                onStop={onStop}
                                canvasWidth="0"
                                canvasHeight="0"
                              />
                              {(recordState === RecordState.STOP ||
                                recordState === RecordState.NONE) && (
                                <Fade>
                                  <button
                                    className="btn btn-record"
                                    onClick={startRecording}
                                    type="button"
                                  >
                                    <FaMicrophone />
                                  </button>
                                </Fade>
                              )}
                              {(recordState === RecordState.START ||
                                recordState === RecordState.PAUSE) && (
                                <Fade>
                                  <button
                                    className="btn btn-pause ms-4"
                                    onClick={
                                      recordState === RecordState.START
                                        ? pauseRecording
                                        : resumeRecording
                                    }
                                    type="button"
                                  >
                                    <span className="me-3">
                                      {hours > 9 ? hours : `0${hours}`}:
                                      {minutes > 9 ? minutes : `0${minutes}`}:
                                      {seconds > 9 ? seconds : `0${seconds}`}
                                    </span>
                                    {recordState === RecordState.START ? (
                                      <FaPause />
                                    ) : (
                                      <FaPlay />
                                    )}
                                  </button>
                                  <button
                                    className="btn btn-save ms-4"
                                    onClick={stopRecording}
                                    type="button"
                                  >
                                    <FaStop />
                                    {/* <FaSave /> */}
                                  </button>
                                </Fade>
                              )}
                            </div>
                          </div>
                          <div className="form-group mt-5">
                            <div className="recorded-audio-list">
                              {
                                story?.recordings?.length > 0 &&
                                <>
                                  {
                                    story?.recordings?.map((rcd,i) => (
                                      <div
                                          className="audio-wrapper d-flex align-items-center mb-2"
                                          key={i}
                                        >
                                          <span className="d-block">{i + 1}.</span>
                                          <audio
                                            controls
                                            className="w-100 mx-4"
                                            src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/recordings/${rcd}`}
                                          ></audio>
                                          <button
                                            type="button"
                                            onClick={() => deleteStoryRecording(rcd)}
                                            className="btn p-0"
                                          >
                                            <FaTrash />
                                          </button>
                                      </div>
                                    ))
                                  }
                                </>
                              }
                              {recordings?.map((recording, i) => (
                                <div
                                  className="audio-wrapper d-flex align-items-center mb-2"
                                  key={i}
                                >
                                  <span className="d-block">{i + 1}.</span>
                                  <audio
                                    controls
                                    className="w-100 mx-4"
                                    src={recording.url}
                                  ></audio>
                                  <button
                                    type="button"
                                    onClick={() => deleteRecording(i)}
                                    className="btn p-0"
                                  >
                                    <FaTrash />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-12 mt-5">
                      <h5 className="text-white mb-4">Media</h5>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="images">
                          Drop two images that are most relatable to your story
                          (Not mandatory)
                        </label>
                        <Dropzone
                          isDragActive={isDragActive}
                          isDragAccept={isDragAccept}
                          isDragReject={isDragReject}
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          thumbs={thumbs}
                        />
                      </div>
                      {
                            (dzError && dzError.length)
                            &&
                            <p className="form-error text-end">{ dzError }</p>
                          }
                    </div>
                    <div className="col-12">
                      <div className="form-group next-btn-wrapper mt-5 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-custom"
                        >
                          <span className="text">Submit</span>
                          <span className="icon">
                            {loading ? (
                              <svg
                                style={{
                                  margin: "auto",
                                  background: "transparent",
                                  display: "block",
                                  shapeRendering: "auto",
                                }}
                                width="200px"
                                height="200px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                              >
                                <circle
                                  cx="50"
                                  cy="50"
                                  fill="none"
                                  stroke="#9d44c0"
                                  strokeWidth="20"
                                  r="34"
                                  strokeDasharray="160.22122533307947 55.40707511102649"
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    repeatCount="indefinite"
                                    dur="0.5681818181818182s"
                                    values="0 50 50;360 50 50"
                                    keyTimes="0;1"
                                  ></animateTransform>
                                </circle>
                              </svg>
                            ) : (
                              <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="96.000000pt"
                                height="96.000000pt"
                                viewBox="0 0 96.000000 96.000000"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <g
                                  transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                  fill="#9d44c0"
                                  stroke="none"
                                >
                                  <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                                </g>
                              </svg>
                            )}
                          </span>
                        </button>
                      </div>
                      {
                            error
                            &&
                            <p className="form-error text-end">Please fill in all the mandatory fields.</p>
                          }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default EditStory;
