import Fancybox from "./Fancybox";
import { FaLinkedinIn, FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { BsFillPlayCircleFill } from 'react-icons/bs'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useRef, useEffect, useState } from "react";
import moment from 'moment'
import HlsPlayer from "../helpers/HlsPlayer";


const StoryContent = ({ story }) => {

    const slider1 = useRef()
    const slider2 = useRef()
    const [url, setUrl] = useState()

    useEffect(() => {
        slider1.current.sync(slider2.current.splide);
      }, [slider1, slider2]);

    useEffect(() => {
        setUrl(window.location.href)
      }, [window.location])

  return (
    <>
    <section className="story-content pt-4 pb-5">
        <div className="container">
            <div className="story-content-wrap">
                <div className="row">
                    <div className="col-xl-8 col-lg-7 order-1 order-lg-0 mt-5 mt-lg-0">

                        <div className="story-slider">
                            <div className="thumbnail-slider-wrap">
                                <Splide
                                    ref={slider1}
                                    options={{
                                        fixedWidth: 90,
                                        fixedHeight: 90,
                                        isNavigation: true,
                                        wheel:true,
                                        gap: 10,
                                        pagination: false,
                                        cover: true,
                                        arrows: true,
                                        direction: 'ttb',
                                        perPage: 4,
                                        height : 460,
                                        heightRatio : 0.3,
                                        dragMinThreshold: {
                                          mouse: 4,
                                          touch: 10,
                                        },
                                        breakpoints : {
                                          991: {
                                              direction: 'ltr',
                                              perPage: 3,
                                              width: '100%',
                                              focus  : 'center',
                                            },
                                            576: {
                                                fixedWidth  : 80,
                                                fixedHeight : 80,
                                            }
                                        },
                                    }}
                                >
                                    {
                                        story?.images?.map((img, i) => (
                                            <SplideSlide key={i}>
                                                <figure className="mb-0">
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${img}`} alt="" />
                                                </figure>
                                            </SplideSlide>
                                        ))
                                    }
                                    {
                                        story.videoStory &&
                                        <SplideSlide>
                                            <figure className="mb-0">
                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/posters/${story?.videoPoster}`} alt="" />
                                                <span className="play-icon">
                                                    <BsFillPlayCircleFill />
                                                </span>
                                            </figure>
                                        </SplideSlide>
                                    }
                                </Splide>
                            </div>
                            <div className="image-preview">
                                <Splide
                                    ref={slider2}
                                    options={{
                                        type : 'fade',
                                        rewind : true,
                                        pagination: false,
                                        arrows : false,
                                    }}
                                >
                                    {
                                        story?.images?.map((img, i) => (
                                            <SplideSlide key={i}>
                                                <Fancybox>
                                                    <a data-fancybox="gallery" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${img}`}>
                                                        <figure className="mb-0">
                                                            <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${img}`} alt="" />
                                                        </figure>
                                                    </a>
                                                </Fancybox>
                                            </SplideSlide>
                                        ))
                                    }
                                    {
                                        story.videoStory &&
                                        <SplideSlide>
                                            <HlsPlayer poster={story.videoPoster} url={story.videoStory} />
                                        </SplideSlide>
                                    }
                                </Splide>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-4 col-lg-5 order-0 order-lg-1">
                        <div className="story-detail-card">
                            <div className="story-header">
                                <figure className="mb-0">
                                    {
                                        story.thumbnail
                                        ?
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${story.thumbnail}`} alt={story.name} />
                                        :
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${story.images[0]}`} alt={story.name} />
                                    }
                                </figure>
                                <div className="story-text">
                                    <h4>{story?.name}</h4>
                                    {
                                        (story?.allowSocialShare && story?.allowSocialShare?.status) ? '' :
                                        <p>{ story.designation?.title }</p>
                                    }
                                </div>
                            </div>
                            <div className="story-body">
                                <div className="location-theme mb-4">
                                    <h5>Campus Location</h5>
                                    <p className="text-capitalize">{story?.campus?.title}</p>
                                </div>
                                <div className="location-theme mb-md-5 mb-4">
                                    <h5>Theme</h5>
                                    <p className="theme-name text-capitalize">{story?.theme?.title}</p>
                                </div>
                                <div className="share mb-5">
                                    <p>
                                        Share :
                                    </p>
                                    <div className="links">
                                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} target="_blank"><FaLinkedinIn /></a>
                                        <a href={`https://www.facebook.com/sharer.php?u=${url}`} target="_blank"><FaFacebookF /></a>
                                        <a href={`https://twitter.com/intent/tweet?url=${url}&text=Check%20out%20this%20amazing%20story!&hashtags=UnileverDiaries`} target="_blank"><FaTwitter/></a>
                                        <a href={`whatsapp://send/?text=Check%20out%20this%20amazing%20story!%0a${url}`} target="_blank"><FaWhatsapp/></a>
                                        <a href={`mailto:?subject=Checkout%20this%20amazing%20story&body=${url}`} target="_blank"><MdEmail/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="story-footer">
                                <div className="publish">
                                    <p>Published On <span className="d-block">{ moment(story.createdAt).format('MMMM DD, YYYY') }</span></p>
                                </div>
                                <div className="views">
                                    <span>
                                        {story?.views} views
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                story?.recordings?.length > 0 &&
                <div className="story-audio-wrap mt-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="headings mb-3">
                                <span className="h5">Play Audio</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="play-story-audio">
                                {
                                    story?.recordings?.map((rec,i) => (
                                        <div
                                        key={i}
                                        className="audio-wrapper d-flex align-items-center mb-2"
                                        >
                                        <audio
                                            controls
                                            className="w-100"
                                            src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/recordings/${rec}`}
                                        ></audio>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </section>
    <section className="story-text-wrap pt-5">
        <div className="container">
            <div className="story-title">
                <h4>{ story.title }</h4>
                <div className="story-content" dangerouslySetInnerHTML={{ __html: story.story }} ></div>
            </div>
        </div>
    </section>
    </>
    )
}

export default StoryContent