import React from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import "@splidejs/react-splide/css";
import { Fade } from "react-reveal";


const ShareYourStory = () => {


  return (
    <section className="share-your-story pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto px-xl-4">
              <div className="headings text-center">
                <Fade bottom>
                  <span className="h4 d-block">
                    Be a part of the larger <span className="d-inline-block">comm-U-nity.</span><span className="d-block">Share your story!</span> 
                  </span>
                  {/* <p>
                    Share your story cause you never know who you would end up inspiring 
                  </p> */}
                </Fade>
              </div>
              <div className="button-wrapper text-center">
                <Fade bottom>
                  <Link to={`/you`} className="btn btn-custom">
                    <span className="text">Tell us your story</span>
                    <span className="icon">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="96.000000pt"
                        height="96.000000pt"
                        viewBox="0 0 96.000000 96.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                          fill="#7706bc"
                          stroke="none"
                        >
                          <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-wrapper">
          <Splide
            options={{
              type: "loop",
              perPage: 1,
              drag: 'free',
              arrows: false,
              pagination: false,
              autoScroll: {
                  speed: .8,
                  autoStart: true,
                  pauseOnFocus: false,
                  pauseOnHover: false
              },
            }}
            extensions={{ AutoScroll }} 
          >
            <SplideSlide>
              <div className="shared-story-item">
                <div className="story story-1">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images1.png" alt="" />
                    </Link>
                </div>
                <div className="story story-2">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images4.png" alt="" />
                    </Link>
                </div>
                <div className="story story-3">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images7.png" alt="" />
                    </Link>
                </div>
                <span className="purple-1"></span>
                <span className="purple-2"></span>
                <span className="purple-3"></span>
                <span className="purple-4"></span>
                <span className="blue-1"></span>
                <span className="blue-2"></span>
                <span className="blue-3"></span>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="shared-story-item">
                <div className="story story-1">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images8.png" alt="" />
                    </Link>
                </div>
                <div className="story story-2">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images2.png" alt="" />
                    </Link>
                </div>
                <div className="story story-3">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images5.png" alt="" />
                    </Link>
                </div>
                <span className="purple-1"></span>
                <span className="purple-2"></span>
                <span className="purple-3"></span>
                <span className="purple-4"></span>
                <span className="blue-1"></span>
                <span className="blue-2"></span>
                <span className="blue-3"></span>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="shared-story-item">
                <div className="story story-1">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images6.png" alt="" />
                    </Link>
                </div>
                <div className="story story-2">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images9.png" alt="" />
                    </Link>
                </div>
                <div className="story story-3">
                    <Link className="d-block" to={`/stories`}>
                      <img src="/images/you/share-your-story-images3.png" alt="" />
                    </Link>
                </div>
                <span className="purple-1"></span>
                <span className="purple-2"></span>
                <span className="purple-3"></span>
                <span className="purple-4"></span>
                <span className="blue-1"></span>
                <span className="blue-2"></span>
                <span className="blue-3"></span>
              </div>
            </SplideSlide>
          </Splide>
        </div>

      <img
        src="/images/homepage/sky-blue-icon-2.svg"
        className="top-left"
        alt=""
      />
      <img
        src="/images/homepage/sky-blue-icon-1.svg"
        className="top-right"
        alt=""
      />
    </section>
  );
};

export default ShareYourStory;
