import React, { useContext } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { GoTriangleRight } from 'react-icons/go'
import { CgProfile } from 'react-icons/cg'
import { ToastContainer } from 'react-toastify'
import AppContext from '../context/AppContext'
import notification from '../helpers/notification'

const Header = ({ setSideMenu }) => {
    const {user,setUser} = useContext(AppContext)
    const navigate = useNavigate()
    const openSideMenu = () => {
        setSideMenu(true)
    }

    const logout = () => {
        localStorage.removeItem('token')
        setUser(null)
        notification("Logged out successfully!", "success");
        navigate('/')
    }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
            <Link to="/" className='navbar-brand'>
                <img src="/images/common/hul-logo.svg" alt="HUL logo" />
            </Link>
            <button className="navbar-toggler ms-auto" type="button" aria-label="Toggle navigation" onClick={openSideMenu}>
                <span className="navbar-toggler-icon">
                    <svg width="30px" height="30px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="#000000ff">
                            <path fill="#005eff" opacity="1.00" d=" M 0.00 94.30 C 0.80 84.28 9.84 75.77 19.97 76.02 C 177.32 75.99 334.69 75.99 492.05 76.02 C 501.96 75.79 510.70 83.91 512.00 93.60 L 512.00 97.68 C 511.19 107.70 502.15 116.20 492.03 115.98 C 334.67 116.01 177.29 116.02 19.93 115.98 C 9.84 116.19 0.85 107.73 0.00 97.75 L 0.00 94.30 Z"></path>
                            <path fill="#005eff" opacity="1.00" d=" M 11.08 238.10 C 15.97 235.48 21.68 236.02 27.03 236.00 C 179.68 236.00 332.33 236.00 484.97 236.00 C 490.33 236.02 496.06 235.48 500.96 238.13 C 506.97 241.01 510.91 247.09 512.00 253.57 L 512.00 257.68 C 511.19 267.69 502.16 276.20 492.05 275.98 C 336.68 276.04 181.30 275.97 25.93 276.01 C 21.18 276.01 16.17 276.34 11.76 274.21 C 5.22 271.34 0.72 264.76 0.00 257.70 L 0.00 254.29 C 0.71 247.49 4.87 241.10 11.08 238.10 Z"></path>
                            <path fill="#005eff" opacity="1.00" d=" M 0.00 414.32 C 0.79 404.27 9.88 395.75 20.02 396.02 C 177.35 395.99 334.67 395.99 491.99 396.02 C 501.92 395.77 510.70 403.88 512.00 413.60 L 512.00 417.70 C 511.26 424.74 506.81 431.31 500.29 434.18 C 495.54 436.49 490.11 435.96 484.99 436.00 C 329.99 435.98 175.00 436.03 20.00 435.98 C 9.89 436.22 0.85 427.76 0.00 417.76 L 0.00 414.32 Z"></path>
                        </g>
                    </svg>
                </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <NavLink className={`nav-link`} to="/" end>Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link`} to={`/you`}>You</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link`} to={`/stories`}>Stories</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link button`} to={`/you`}>
                            <span className='text'>Write Your Story</span>
                            <GoTriangleRight />
                        </NavLink>
                    </li>
                    {
                        user ?
                        <li className="nav-item dropdown-wrap p-0 d-flex align-items-center">
                            <div className="profile">
                                <CgProfile/>
                                <span>{user?.name.split(' ')[0]}</span>
                            </div>
                            <div className="dropdown">
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link to="/profile">Profile</Link>
                                    </li>
                                    <li>
                                         <Link to="/my-stories">My Stories</Link>
                                    </li>
                                    <li>
                                         <button onClick={logout}>Logout</button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        :
                    <li className="nav-item p-0 login_btn">
                        <NavLink className={`nav-link`} to={`/login`}>
                            <CgProfile/>
                            <span>Login</span>
                        </NavLink>
                    </li>
                    }
                   
                </ul>
            </div>
        </div>
        <ToastContainer />
    </nav>
  )
}

export default Header