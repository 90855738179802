
import React, { useRef } from 'react'
import BannerCarousel from '../components/BannerCarousel'
import Discover from '../components/Discover'
import Footer from '../components/Footer'
import Header from '../components/Header'
// import Podcast from '../components/Podcast'
import SideMenu from '../components/SideMenu'
import Themes from '../components/Themes'
import MetaData from '../helpers/MetaData'

const Stories = ({ setSideMenu, sideMenu }) => {

  const themeRef = useRef()

  return (
    <main>
        <MetaData title='Stories | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
        <Header setSideMenu={setSideMenu} />
        <BannerCarousel themeRef={themeRef} />
        <Themes themeRef={themeRef} />
        <Discover/>
        {/* <Podcast/> */}
        <Footer />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
  )
}

export default Stories