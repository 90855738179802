import React from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'react-router-dom'
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { FaLinkedinIn, FaFacebookF } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
        <div className="container">
            <div className="top-footer">
                <div className="row align-items-center mb-4">
                    <div className="col-lg-6">
                        <span className="d-block hob mb-2">Curated by</span>
                        <a href={`https://www.humansofbombay.in/`} rel={'nofollow'} target={'_blank'}>
                            <img src='/images/common/humans-of-bombay-logo.svg' />
                        </a>
                    </div>
                </div>
                <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                    <div className="left">
                        <Link className='d-block mb-2' to={`/`}>Hindustan Unilever Limited</Link>
                        <span className="d-block">&copy; Unilever {new Date().getFullYear()}</span>
                    </div>
                    <div className="right ms-lg-5">
                        <div className="social-icons mt-lg-0">
                            <a className='mb-0' href="https://twitter.com/HUL_News">
                                <BsTwitter />
                            </a>
                            <a className='mb-0' href="https://www.linkedin.com/company/unilever/">
                                <FaLinkedinIn />
                            </a>
                            <a className='mb-0' href="https://www.instagram.com/unileverdiariesindia/">
                                <BsInstagram />
                            </a>
                            <a className='mb-0' href="https://www.facebook.com/HULDiaries">
                                <FaFacebookF />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                    <Fade bottom>
                        <div className="nav-item pb-2 pb-lg-0">
                            <Link to={`/`}>Home</Link>
                        </div>
                        {/* <div className="nav-item pb-2 pb-lg-0">
                            <Link to={`/you`}>You</Link>
                        </div>
                        <div className="nav-item pb-2 pb-lg-0">
                            <Link to={`/stories`}>Stories</Link>
                        </div> */}
                        <div className="nav-item pb-2 pb-lg-0">
                            <a target={`_blank`} href="/images/common/global-employee-privacy-notice.pdf">Global Employee Privacy Notice</a>
                        </div>
                        <div className="nav-item pb-2 pb-lg-0">
                            <a target={`_blank`} href={`https://www.hul.co.in/legal/`}>Terms &amp; Conditions</a>
                        </div>
                        {/* <div className="nav-item pb-2 pb-lg-0">
                            <Link to={`/terms-of-service`}>Terms of service</Link>
                        </div> */}
                        <div className="nav-item pb-2 pb-lg-0">
                            <a target={`_blank`} href="https://www.unilevernotices.com/privacy-notices/india-english.html">Privacy Policy</a>
                        </div>
                        <div className="nav-item pb-2 pb-lg-0">
                            <Link to={`/faqs`}>FAQs</Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
        <img src="/images/common/footer-element.png" alt="Footer" className='footer-img' />
        <div className="footer-strip">
            <div className="d-flex">
                <span className="pink"></span>
                <span className="yellow"></span>
                <span className="red"></span>
                <span className="mint"></span>
            </div>
        </div>
    </footer>
  )
}

export default Footer