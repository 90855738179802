import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const FAQAccordion = () => {
  return (
    <section className='faq-accordion pt-0'>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> Where can I find my story?</Accordion.Header>
                    <Accordion.Body>
                    You can find your story on the stories page. You can also search for it as per the theme of the story. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header> Who can I contact from HUL if I have any questions?</Accordion.Header>
                    <Accordion.Body>
                    You can reach out to us at <a href="mailto:nikhita.george@unilever.com">nikhita.george@unilever.com</a> and <a href="mailto:nazli.shaikh@unilever.com">nazli.shaikh@unilever.com</a>.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header> How long does it take to receive a response from HOB about my story?</Accordion.Header>
                    <Accordion.Body>
                    You can expect a response within 72 working hours.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header> How do our stories get approved?</Accordion.Header>
                    <Accordion.Body>
                    Once you have submitted your story, it is checked by the content team of Humans of Bombay and jointly approved by HUL and Humans of Bombay.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header> If I decide to share my story, where will it be used?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        If approved, your story will be available for people to read on this website and HUL's social media pages.
                      </p>
                      <p>
                        HOB can also post it on their social media pages, if shortlisted.
                      </p>
                      <p className='mb-0'>
                        You will be consulted before your story is published on mediums other than the ones mentioned.
                      </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header> How many stories can I submit?</Accordion.Header>
                    <Accordion.Body>
                    You can submit as many stories as you want as long as they are different narratives.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>I am unable to submit my story, what should I do?</Accordion.Header>
                    <Accordion.Body>
                    Kindly check your internet connection or try submitting it from a different device. If you are still unable to submit your story, please reach out to <a href="mailto:unileverdiaries@humansofbombay.in">unileverdiaries@humansofbombay.in</a> with the following subject line "Name - Can't Submit My Story".  In the email, please mention your contact number and base location for easier communication. If possible,  please add screenshots of the error you are receiving.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header> I want to withdraw my story, who should I reach out to?</Accordion.Header>
                    <Accordion.Body>
                    Please reach out to <a href="mailto:unileverdiaries@humansofbombay.in">unileverdiaries@humansofbombay.in</a> with the following subject line 
                    "Name - Request To Withdraw My Story". In the email, please mention your contact number, base location, and reason for easier communication.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQAccordion