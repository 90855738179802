import React, { useEffect, useState } from 'react'
import StoryCard from './StoryCard';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Fade } from 'react-reveal';
import notification from '../helpers/notification';
import ApiService from '../service/ApiService';


const LatestStories = () => {

    // const stories = [
    //     {
    //         img: `/images/homepage/latest-stories-image-1.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-2.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-3.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-4.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-5.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-6.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky - I got the dog AND I got the guy!`
    //     },
    //     {
    //         img: `/images/homepage/latest-stories-image-7.png`,
    //         name: `Anupa Patil`,
    //         text: `I think I'm just lucky got the dog AND I got the guy!`
    //     },
    // ]

    const [stories, setStories] = useState([])

    const fetchLatestStories = async () => {
        try {
            let res = await ApiService.fetchLatestStories()
            setStories(res)
        } catch (error) {
            console.log(error)
            notification("Something went wrong! Please try again.", "error");
        }
    }

    useEffect(() => {
        fetchLatestStories()
    }, [])


  return (
    <section className="latest-stories" id='latestStories'>
        <div className="container">
            <div className="headings">
                <Fade bottom>
                    <span className="h4">Stories</span>
                </Fade>
            </div>
            <Fade bottom>
                <div className="latest-stories-desktop d-none d-lg-block">
                    <div className="row">
                        {
                            stories?.map((story, i) => (
                                <div className="col-md-6 col-lg-3" key={i}>
                                    <StoryCard story={story} type="story" />
                                </div>
                            ))
                        }
                        <div className="col-md-6 col-lg-3">
                            <StoryCard type="cta" />
                        </div>
                        {/* {
                            stories.slice(3, 7).map((story, i) => (
                                <div className="col-md-6 col-lg-3" key={i}>
                                    <StoryCard story={story} type="story" />
                                </div>
                            ))
                        } */}
                    </div>
                </div>
            </Fade>
            <div className="latest-stories-mobile d-block d-lg-none">
                <Splide
                className="latest-stories-slider"
                options={{
                    type: "loop",
                    perPage: 1,
                    arrows: true,
                    pagination: true,  
                    autoplay: true,
                    interval: 4000           
                }}
                >
                    {
                        stories?.map((story, i) => (
                            <SplideSlide key={i}>
                                <StoryCard story={story} type="story" />
                            </SplideSlide>
                        ))
                    }
                    <SplideSlide>
                        <StoryCard type="cta" />
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    </section>
  );
}

export default LatestStories