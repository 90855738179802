import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import UserLogin from "../components/UserLogin.js";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";

const Login = ({ setSideMenu, sideMenu }) => {
  const {user} = useContext(AppContext);
  const navigate = useNavigate()

  useEffect(() => {
    if(user) {
      navigate('/',{replace:true})
    }
  },[user])
  
  return (
    <>
      <MetaData title='Login | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
      <Header setSideMenu={setSideMenu} />
      <main>
        <UserLogin />
      </main>
      <Footer />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default Login;
