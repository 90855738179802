import React from 'react'
import { Link } from 'react-router-dom'

const StoryCard = ({ story, type }) => {
    if(type === 'story') {
        return (
          <div className="story-card-wrapper">
              <Link to={`/story/${story.slug}`} className="story-card story d-block">
                  <figure className="mb-0">
                    {
                      story.thumbnail
                      ?
                      <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${story.thumbnail}`}
                      alt={story.title}
                      className="w-100"
                      />
                      :
                      <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${story.images[0]}`}
                      alt={story.title}
                      className="w-100"
                      />
                    }
                  </figure>
                  <div className="story-text">
                      <p>{ story.excerpt ? story.excerpt : story.title }</p>
                      <span className="name">{ story.name }</span>
                  </div>
              </Link>
          </div>
        )
    } else {
        return (
          <div className="story-card-wrapper" style={{ backgroundColor: '#47c8ff' }}>
            <div className="story-card cta">
              <figure className="mb-0">
                <img
                  src={`/images/homepage/nickel-yellow-icon.svg`}
                  alt="Story Title"
                  className="w-100"
                />
              </figure>
              <Link to={`/stories`} className="cta-text">
                <span className="h2">Click for more Inspiration</span>
                <div className="cta-link-wrapper text-center">
                    <div className='btn btn-custom view-all'>
                        <span className='text'>View all stories</span>
                        <span className="icon">
                            <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="96.000000pt"
                            height="96.000000pt"
                            viewBox="0 0 96.000000 96.000000"
                            preserveAspectRatio="xMidYMid meet"
                            >
                            <g
                                transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                fill="#7706bc"
                                stroke="none"
                            >
                                <path
                                d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                                />
                            </g>
                            </svg>
                        </span>
                    </div>
                </div>
              </Link>
            </div>
          </div>
        );
    }
}

export default StoryCard