import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import UpdateProfile from '../components/UpdateProfile'
import MetaData from '../helpers/MetaData'

const Profile = ({ setSideMenu, sideMenu }) => {
  return (
    <>
        <MetaData title='My Profile | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
        <Header setSideMenu={setSideMenu} />
        <main>
            <UpdateProfile />
        </main>
        <Footer />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  )
}

export default Profile