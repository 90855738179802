import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'
import FeedCard from './FeedCard'
import debounce from 'lodash.debounce';

const Feed = ({ feeds, theme, changePage, page, totalPages }) => {

    const [search, setSearch] = useState('')
    const [results, setResults] = useState([])

    const _searchStories = async (text) => {
        try {
            let res = await ApiService.searchStories({ text: text })
            // console.log(res)
            setResults(res)
        } catch (error) {
            console.log(error)
            notification("Something went wrong! Please try again.", "error");
        }
    }

    const searchStories = debounce(_searchStories, 300)

    useEffect(() => {
        if(search.length > 2) {
            searchStories(search)
        }
    }, [search])


  return (
    <section className="feeds pt-0" id='feeds'>
        <div className="container">
            <div className="headings d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <span className="h4 mb-4 mb-lg-0">{ theme.title }</span>
                <div className="search-stories-wrapper">
                    <input type="text" placeholder='Search stories' value={search} onChange={e => setSearch(e.target.value)} className='form-control search-stories' />
                    <ul className='search-results list-unstyled mb-0'>
                        {   
                            (search.length && results.length)
                            ?
                            results.map(item => (
                                <li key={item._id}>
                                    <Link to={`/story/${item.slug}`}>
                                        { item.name } - {item?.title?.substring(0, 20)}...
                                    </Link>
                                </li>
                            ))
                            :
                            <></>
                        }
                    </ul>
                </div>
            </div>
            <div className="row">
                {
                    feeds.map(feed => (
                        <div className="col-md-6 col-lg-4 mb-4" key={feed._id}>
                            <FeedCard feed={feed} />
                        </div>
                    ))
                }
            </div>
            {
                feeds.length > 9
                &&
                <div className="custom-pagination mt-5">
                    <div className="d-flex align-items-center justify-content-center">
                        <span className='me-4'>Page</span>
                        {
                            (page !== 1)
                            &&
                            <button onClick={() => changePage(1)} className={`page-link border-0 mx-2 ${page === 1 && `active` }`}>First Page</button>
                        }
                        {
                            ((page - 1) > 0)
                            &&
                            <button onClick={() => changePage(page - 1)} className={`page-link border-0 mx-2 ${page === (page - 1) && `active` }`}>{page - 1}</button>
                        }
                        <button onClick={() => changePage(page)} className={`page-link border-0 mx-2 active`}>{page}</button>
                        {
                            ((page + 1) <= totalPages)
                            &&
                            <button onClick={() => changePage(page + 1)} className={`page-link border-0 mx-2 ${page === (page + 1) && `active` }`}>{page + 1}</button>
                        }
                        {
                            ((totalPages > 3) && (page !== totalPages))
                            &&
                            <>
                                <span>...</span>
                                <button onClick={() => changePage(totalPages)} className={`page-link border-0 mx-2 ${page === (totalPages) && `active` }`}>Last Page</button>
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    </section>
  )
}

export default Feed