import moment from 'moment';
import React from 'react'
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom'

const FeedCard = ({ feed }) => {

  return (
    <div className="feed-card-wrapper p-1">
      <div className="feed-card">
        <div className="feed-card-header d-flex align-items-center justify-content-between">
          <div className="profile d-flex align-items-center">
            <figure className="mb-0">
              {
                feed.thumbnail
                ?
                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${feed.thumbnail}`} alt="" />
                :
                <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${feed.images[0]}`} alt="" />
              }
            </figure>
            <div className="text ms-3">
              <Link to={`/story/${feed.slug}`}>{ feed.name }</Link>
              {
                (feed?.allowSocialShare && feed?.allowSocialShare?.status) ? 
                '' 
                :
                <p className="mb-0">{ feed.designation?.title }</p>
              }
            </div>
          </div>
          <div className="views">
            <span>{ feed.views } views</span>
          </div>
        </div>
        <div className="feed-body p-1">
          <Link to={`/story/${feed.slug}`}>
            <figure className="mb-0">
              {
                feed.thumbnail
                ?
                <img
                src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${feed.thumbnail}`}
                alt="Akshay Patil"
                className='feed-image'
                />
                :
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${feed.images[0]}`}
                  alt="Akshay Patil"
                  className='feed-image'
                />
              }
              {/* <img src="/images/stories/unilever-u.png" className='unilever-logo' alt="" /> */}
            </figure>
          </Link>
        </div>
        <div className="feed-footer">
          <p className=''>
            { feed?.excerpt ? (feed?.excerpt?.length > 80 ? `${feed?.excerpt?.slice(0,80)}...` : `${feed?.excerpt}...`) : (feed?.title?.length > 80 ? `${feed?.title?.slice(0,80)}...` : `${feed.title}...`) }
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <Link to={`/story/${feed.slug}`} className='read-more'>
                Read more <BsArrowRight className='ms-2' size={20} />
            </Link>
            <span className="date">
                { moment(feed.createdAt).format('MMMM DD, YYYY') }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedCard