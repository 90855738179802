import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import notification from "../helpers/notification";
import ApiService from "../service/ApiService";
import AppContext from '../context/AppContext'

const UserLogin = ({youPage=false}) => {

  const {fetchUser} = useContext(AppContext)
  const navigate = useNavigate()

  const [error, setError] = useState({})
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  })

  const validate = data => {
    setError({})
    let hasError = false;

    if(!data.email){
      hasError = true;
      setError(prev => ({
        ...prev,
        email: 'Please Enter Email'
      }))
    }
    if(!data.password){
      hasError = true;
      setError(prev => ({
        ...prev,
        password: 'Please Enter Password'
      }))
    }
    if(hasError) {
      return false
    }else {
      return true
    }
  }

const handleLogin = async e => {
  e.preventDefault()
  const isValidate = validate(loginDetails)
  if(isValidate){
      try {
        const {message, token} = await ApiService.login(loginDetails)
        notification('Logged in successfully!', 'success')
        localStorage.setItem('token', token)
        setTimeout(() => {
          fetchUser()
          navigate('/')
        },500)
      }catch(err) {
        console.log(err)
        notification(err.response.data.message, 'error')
      }
  }
}

  return (
    <section className={`tell-your-story auth-content ${youPage ? 'mt-0' : ''}`}>
      <div className="container">
        <div className="headings">
          <span className="h4 d-block">Login</span>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="you-form-wrapper">
          <div className="form-content auth">
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="name">Email *</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  value={loginDetails.email}
                  onChange={(e) =>
                    setLoginDetails((prevData) => ({
                      ...prevData,
                      email: e.target.value,
                    }))
                  }
                />
                {
                error.email && <small className="text-warning">{error.email}</small>
              }
              </div>
              <div className="form-group">
                <label htmlFor="name">Password *</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter your password"
                  value={loginDetails.password}
                  onChange={(e) =>
                    setLoginDetails((prevData) => ({
                      ...prevData,
                      password: e.target.value,
                    }))
                  }
                />
                {
            error.password && <small className="text-warning">{error.password}</small>
          }
              </div>
              <div className="form-group mt-4 next-btn-wrapper">
                <button type="submit" className="btn btn-custom">
                  <span className="text">Login</span>
                  <span className="icon">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96.000000pt"
                      height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                        fill="#9d44c0"
                        stroke="none"
                      >
                        <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="form-group d-md-flex justify-content-between mb-0">
                <p className="mb-0">
                  <Link className="text-warning" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </p>
                <p className="mb-0">
                  Don't have an account?{" "}
                  <Link className="text-warning" to="/signup">
                    Register
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <img
        src="/images/homepage/sky-blue-icon-2.svg"
        className="top-left"
        alt=""
      />
      <img
        src="/images/homepage/sky-blue-icon-1.svg"
        className="top-right"
        alt=""
      />
    </section>
  );
};

export default UserLogin;
