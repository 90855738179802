import React, { useContext } from 'react'
import {  NavLink, useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext'
import notification from '../helpers/notification'

const SideMenu = ({ sideMenu, setSideMenu }) => {

    const {user, setUser} = useContext(AppContext)
    const navigate = useNavigate()



    const closeSideMenu = () => {
        setSideMenu(false)
    }
    
    const logout = () => {
        localStorage.removeItem('token')
        setUser(null)
        setSideMenu(false)
        notification("Logged out successfully!", "success");
        navigate('/')
    }

  return (
      <div className={`side-menu-wrapper ${ sideMenu ? `open` : `` }`}>
        <nav>
            <div className="navbar-logo d-flex align-items-center justify-content-between">
                <figure className="mb-0">
                    <img src="/images/common/hul-logo.svg" width={210} alt="HUL Logo" />
                </figure>
                <button className="btn me-4 close-btn" onClick={closeSideMenu} >
                    <svg className="close-collapse" width="25px" height="25px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="#000000ff">
                            <path opacity="1.00" d=" M 22.44 0.00 L 27.45 0.00 C 33.11 0.82 38.61 3.17 42.65 7.30 C 113.81 78.37 184.86 149.54 256.01 220.61 C 326.47 150.20 396.91 79.76 467.32 9.30 C 471.74 4.31 477.79 0.85 484.45 0.00 L 489.52 0.00 C 501.07 1.21 510.72 10.90 512.00 22.44 L 512.00 27.50 C 511.14 33.40 508.52 39.06 504.18 43.20 C 433.26 114.14 362.30 185.04 291.40 256.00 C 362.31 326.97 433.28 397.86 504.18 468.82 C 508.51 472.99 511.13 478.65 512.00 484.57 L 512.00 489.37 C 510.76 501.51 500.13 511.72 487.85 512.00 L 484.59 512.00 C 478.89 511.19 473.40 508.77 469.32 504.66 C 398.21 433.58 327.14 362.45 256.01 291.39 C 185.05 362.29 114.15 433.25 43.20 504.16 C 39.08 508.51 33.44 511.12 27.55 512.00 L 24.20 512.00 C 11.94 511.74 1.30 501.58 0.00 489.48 L 0.00 484.63 C 0.82 478.68 3.46 473.00 7.83 468.83 C 78.75 397.88 149.72 326.97 220.63 256.00 C 149.55 184.85 78.39 113.78 7.31 42.64 C 3.21 38.63 0.85 33.18 0.00 27.56 L 0.00 22.52 C 1.20 10.96 10.90 1.25 22.44 0.00 Z"></path>
                        </g>
                    </svg>
                </button>
            </div>
            <ul className="mb-4">
                <li className='active'>
                    <NavLink onClick={closeSideMenu} className="nav-link" to="/"> <span> Home</span> </NavLink>
                </li>
                <li>
                    <NavLink onClick={closeSideMenu} className="nav-link" to="/you"> <span>You</span></NavLink>
                </li>
                <li>
                    <NavLink onClick={closeSideMenu} className="nav-link" to="/stories">Stories</NavLink>
                </li>
                {
                    user
                    ?
                    <>
                        <li>
                            <NavLink onClick={closeSideMenu} className="nav-link" to="/profile">Profile</NavLink>
                        </li>
                        <li>
                            <button onClick={logout} className="btn nav-link">Logout</button>
                        </li>
                    </>
                    :
                    <li>
                        <NavLink onClick={closeSideMenu} className="nav-link" to="/login">Login</NavLink>
                    </li>
                }
            </ul>

            <div className="button-wrapper p-1">
                <a href={`/you`} className='btn btn-custom'>
                    <span className='text'>View stories</span>
                    <span className="icon">
                        <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="96.000000pt"
                        height="96.000000pt"
                        viewBox="0 0 96.000000 96.000000"
                        preserveAspectRatio="xMidYMid meet"
                        >
                        <g
                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                            fill="#005eff"
                            stroke="none"
                        >
                            <path
                            d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                            />
                        </g>
                        </svg>
                    </span>
                </a>
            </div>


        </nav>
      </div>
  )
}

export default SideMenu