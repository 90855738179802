import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import MetaData from "../helpers/MetaData";
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {AiFillCaretLeft, AiFillCaretRight} from 'react-icons/ai'
import { useContext } from "react";
import AppContext from "../context/AppContext";
import ApiService from "../service/ApiService";
import { useEffect } from "react";

const MyStories = ({ setSideMenu, sideMenu }) => {
  const {user} = useContext(AppContext);
  const [stories, setStories] = useState([]);
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(12)

  const fetchUserStories = async () => {
    try {
      const {stories, total} = await ApiService.fetchUserStories(page, perPage)
      setStories(stories)
      setTotal(total)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(user) {
      fetchUserStories()
    }
  },[user, page])

  return (
    <>
      <MetaData title='My Stories | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
      <Header setSideMenu={setSideMenu} />
      <main>
        <section className="my-stories">
          <div className="container">
            <div className="headings mb-5">
              <span className="h4 d-block">My Stories</span>
            </div>
            {
              stories.length > 0 ?
              <div className="row">
                {
                  stories.map(story => (
                    <div className="col-lg-6 mb-4" key={story._id}>
                    <div className="my-story-card">
                    <div className="row">
                    <div className="col-sm-4">
                        <figure className="mb-0">
                          {
                            story.images.length > 0 ?
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${story.images[0]}`} className="w-100" alt="" />
                            :
                            <img src="/images/homepage/nickel-yellow-icon.svg" className="_placeholder" alt="" />
                          }
                        </figure>
                    </div>
                    <div className="col-sm-8 mt-4 mt-sm-0">
                      <ul className="list-unstyled mb-0">
                        <li>
                          <span className="key">Designation</span>  
                          <span className="value">{story?.designation?.title}</span>  
                        </li>  
                        <li>
                          <span className="key">Base Location</span>  
                          <span className="value">{story?.campus?.title}</span>  
                        </li>  
                        <li>
                          <span className="key">Theme</span>  
                          <span className="value">{story?.theme?.title}</span>  
                        </li>  
                      </ul> 
                      <Link to={`/edit-story/${story.slug}?type=${story?.recordings?.length > 0 ? 'audio' : 'text'}`} className="btn btn-custom mt-3">
                        <span>Edit Story</span>
                          <span className="icon ms-0">
                            <AiFillCaretRight fill="#fff" />
                          </span>
                        </Link>
                    </div>
                  </div>
                    </div>
                  </div>
                  ))
                }
                
                {
                  perPage < total &&
                  <div className="col-12">
                    <div className="pagination-wrap">
                      <div className="_pagination">
                        <button disabled={page <= 1} onClick={() => setPage(page-1)}><AiFillCaretLeft /></button>
                        <span>{page}</span>
                        <button disabled={page >= perPage} onClick={() => setPage(page+1)}><AiFillCaretRight/></button>
                      </div>
                    </div>
                  </div>
                }
              </div> : 
              <div className="no-stories-wrap not-found">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                  <div className="headings text-left">
                    <span className="h1 d-block">
                    You don't Have Any Stories.
                    </span>
                    <p>
                    You have not written any stories yet.
                    </p>
                  </div>
                  <Link to={`/you`} className="btn btn-custom">
                  <span className="text me-1">Write One!</span>
                    <span className="icon">
                      <AiFillCaretLeft fill="#7705bc" />
                    </span>
                  </Link>
                  </div>
                  <div className="col-lg-5">
                    <figure>
                      <img src="/images/404/not-found.png" className="w-100" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </main>
      <Footer />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default MyStories;
