import React, { useContext } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import TellYourStory from '../components/TellYourStory'
import UserLogin from '../components/UserLogin'
import YouBanner from '../components/YouBanner'
import AppContext from '../context/AppContext'
import MetaData from '../helpers/MetaData'

const You = ({ setSideMenu, sideMenu }) => {

  const {user} = useContext(AppContext)

  return (
    <main>
        <MetaData title='You | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
        <Header setSideMenu={setSideMenu} />
        <YouBanner />
        {
          user ?
          <TellYourStory />:
          <UserLogin youPage={true} />
        }
        <Footer />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
  )
}

export default You