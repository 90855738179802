import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'
import ThemeCard from './ThemeCard'

const ThemeSlider = ({ themes, slug }) => {

  return (
    <section className='theme-slider'>
        <div className="container-fluid">
            <div className="row mx-auto justify-content-center">
                {
                    themes.map(theme => (
                        <div className="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0" key={theme._id}>
                            <ThemeCard slug={theme.slug} image={theme.image} title={theme.title} status={slug === theme.slug ? `active` : ''} />
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
  )
}

export default ThemeSlider