import Footer from '../components/Footer'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import StoryDetailsHeader from '../components/StoryDetailsHeader'
import StoryContent from '../components/StoryContent'
import InspiringStories from '../components/InspiringStories'
import TellStories from '../components/TellStories'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '../service/ApiService'
import { useEffect, useState } from 'react'
import notification from '../helpers/notification'
import MetaData from '../helpers/MetaData'


const StoryDetails = ({sideMenu, setSideMenu}) => {
  const navigate = useNavigate()
  let { slug } = useParams()
  const [story, setStory] = useState()
  const [feeds, setFeeds] = useState([])

  const fetchStory = async () => {
    try {
      let res = await ApiService.fetchStory(slug)
      setStory(res.story)
      setFeeds(res.feeds)
    } catch (error) {
      if(error.response.data === 'Bad Request' || error.response.status === 400) {
        navigate('/404')
      }
      notification('Something went wrong! Please try refreshing the page.', 'error')
    }
  }

  useEffect(() => {
    fetchStory()
    // fetchFeeds()
  }, [slug])
  


  return (
    <main>
        <MetaData 
          title={`${story?.name} | Unilever Diaries`} 
          description='Check out the amazing story! Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'
          shareImg={story?.images[0]}
          shareTitle={story?.title}
        />
        <Header setSideMenu={setSideMenu} />
        {
          story
          &&
          <StoryDetailsHeader story={story} />
        }
        {
          story
          &&
          <StoryContent story={story} />
        }
        {
          feeds
          &&
          <InspiringStories feeds={feeds} />
        }
        <TellStories story={story} />
        <Footer />
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </main>
  )
}

export default StoryDetails