import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import ApiService from "../service/ApiService";
import notification from "../helpers/notification";
import MetaData from "../helpers/MetaData";

const ForgotPassword = ({ setSideMenu, sideMenu }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false)

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError(false)
    if(!email) {
      setError(true);
      return false;
    }
    try {
      const { message } = await ApiService.forgotPassword({email});
      notification(message, "success");
    } catch (err) {
      console.log(err);
      if(err.response.status === 400) {
        notification(err.response.data.message, "warning");
      }else{
        notification(err.response.data.message, "error");
      }
    }
  };

  return (
    <>
      <MetaData title='Forgot Password | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
      <Header setSideMenu={setSideMenu} />
      <main>
        <section className="tell-your-story auth-content">
          <div className="container">
            <div className="headings">
              <span className="h4 d-block">Forgot Password</span>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
            </div>
            <div className="you-form-wrapper">
              <div className="form-content auth">
                <form onSubmit={handleForgotPassword}>
                  <div className="form-group">
                    <label htmlFor="name">Email *</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {
                      error && <small className="text-warning">Please Enter Email</small>
                    }
                  </div>
                  <div className="form-group mt-4 next-btn-wrapper">
                    <button type="submit" className="btn btn-custom">
                      <span className="text">Submit</span>
                      <span className="icon">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="96.000000pt"
                          height="96.000000pt"
                          viewBox="0 0 96.000000 96.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                            fill="#9d44c0"
                            stroke="none"
                          >
                            <path d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z" />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="form-group mb-0">
                    <p className="text-end mb-0">
                      <Link className="text-warning" to="/login">
                        Login
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <img
            src="/images/homepage/sky-blue-icon-2.svg"
            className="top-left"
            alt=""
          />
          <img
            src="/images/homepage/sky-blue-icon-1.svg"
            className="top-right"
            alt=""
          />
        </section>
      </main>
      <Footer sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default ForgotPassword;
