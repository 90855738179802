import React, { useState } from 'react'
import { Fade } from 'react-reveal'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


const Achievements = () => {

    const [countUpCompleted, setCountUpCompleted] = useState(false)

    const onChange = () => {
        console.log(1)
    }

  return (
    <section className="achievements">
        <Fade bottom>
            <div className="container">
                <div className="row align-items-baseline">
                    <div className="col-lg-4 align-self-end mb-5 mb-lg-0">
                        <div className="headings">
                            <span className="h4">Numbers <span className="d-lg-block">That Matter</span></span>
                            <img src="/images/homepage/our-achievements-icon.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg px-1 mb-1 mb-lg-0">
                        <div className="achievement-card primary-color dark">
                            <div className="text">
                                <span className="d-block">
                                <CountUp end={90} prefix={`~`} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={!countUpCompleted ? start : console.log(1) } delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                </span>
                                <p>Years of presence in India</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg px-1 mb-1 mb-lg-0">
                        <div className="achievement-card primary-color">
                            <div className="text">
                                <span className="d-block">
                                <CountUp end={50} suffix={`+`} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={!countUpCompleted ? start : console.log(1) } delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                </span>
                                <p>FMCG brands in India</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg px-1 mb-1 mb-lg-0">
                        <div className="achievement-card primary-color dark">
                            <div className="text">
                                <span className="d-block">
                                    No. 1
                                </span>
                                <p>Employer of choice across industries</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    </section>
  )
}

export default Achievements