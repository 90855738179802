import fetch from "../auth/FetchInterceptor";

let ApiService = {};

ApiService.fetchCampusTheme = () => {
  return fetch({
    url: "/fetch-campus-theme",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.postStory = (data) => {
  return fetch({
    url: "/story",
    method: "POST",
    data: data,
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.updateStory = ({formData, id}) => {
  return fetch({
    url: `/story/${id}/edit`,
    method: "PUT",
    data: formData,
  });
};
ApiService.fetchFeeds = (slug, page) => {
  return fetch({
    url: `/fetch-feeds?slug=${slug}&page=${page}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchStory = (slug) => {
  return fetch({
    url: `/fetch-story/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchStoryBySlug = (slug) => {
  return fetch({
    url: `/fetch-story/${slug}/view`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchLatestStories = () => {
  return fetch({
    url: `/fetch-latest-stories/`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchWeeklyStories = () => {
  return fetch({
    url: "/fetch-weekly-stories",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDesignation = () => {
  return fetch({
    url: "/fetch-designation",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.searchStories = ({ text }) => {
  return fetch({
    url: `/search?query=${text}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.register = (data) => {
  return fetch({
    url: "/register",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchUser = () => {
  return fetch({
    url: "/user",
    method: "GET",
  });
};

ApiService.fetchUserById = data => {
  return fetch({
    url: `/user/${data.id}?story=${data.story}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
}

ApiService.login = (data) => {
  return fetch({
    url: "/login",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updateProfile = (data) => {
  return fetch({
    url: `/update-profile`,
    method: "PUT",
    data,
  });
};

ApiService.forgotPassword = (data) => {
  return fetch({
    url: "/forgot-password",
    method: "POST",
    headers: {
      "public-request": "true",
    },
    data,
  });
};

ApiService.resetPassword = (data) => {
  return fetch({
    url: `/password/reset/${data.token}`,
    method: "PUT",
    data: data.data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.changePassword = (data) => {
  return fetch({
    url: '/change-password',
    method: 'PUT',
    data
  })
}

ApiService.verifyEmailAddress = link => {
  return fetch({
    url: `/verify-email/${link}`,
    method: 'PUT',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.agreeConsentForm = data => {
  return fetch({
    url: "/agree-consent-form",
    method: "PUT",
    data,
    headers: {
      "public-request": "true",
    },
  });
}

ApiService.fetchUserStories = (page, perPage) => {
  return fetch({
    url:`/fetch-user-stories?page=${page}&perPage=${perPage}`,
    method: 'GET'
  })
}

ApiService.deleteStoryImg = (slug, filename) => {
  return fetch({
    url:`/delete-story-image/${slug}?filename=${filename}`,
    method: 'PUT'
  })
}

ApiService.deleteStoryRecording = (slug, recording) => {
  return fetch({
    url:`/delete-story-recording/${slug}?recording=${recording}`,
    method: 'PUT'
  })
}

export default ApiService;
