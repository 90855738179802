import { Helmet } from 'react-helmet'

const MetaData = ({title, description, shareImg, shareTitle}) => {
  return (
    <Helmet prioritizeSeoTags>
        {
          shareTitle ?
          <meta property="og:title" content={shareTitle} />
          :
          <meta property="og:title" content="Check out the amazing story!"/>
        }
        {
          shareImg ?
          <meta property="og:image" content={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/images/${shareImg}`} />
          :
          <meta property="og:image" content="https://unileverdiaries.com/images/homepage/how-it-works-background-image.jpg"/>
        }
        <title>{title}</title>
        <meta name="description" content={description}/>
    </Helmet>
  )
}

export default MetaData