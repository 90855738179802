import React, { useEffect, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import ApiService from '../service/ApiService';
function Discover() {

    const [weeklyStories, setWeeklyStories] = useState([])

    const fetchWeeklyStories = async () => {
        try {
            const res =  await ApiService.fetchWeeklyStories();
            setWeeklyStories(res.stories)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {

        fetchWeeklyStories()

    },[])

  return (
    <section className='best-stories'>
      <div className='container'>
         <div className='col-lg-7 mx-auto'>
            <div className="headings text-center">
                <Fade bottom>
                    <span className="h4 d-block text-white">Extraordinary stories to inspire you everyday</span>
                    {/* <p className='text-white'>Uniquely Unilever</p> */}
                </Fade>
            </div>
         </div> 
         <div className="stories-slider-wrap">
            <Splide
            className="banner-slider"
            options={{
                type: "slide",
                perPage: 1,
                arrows: false,
                pagination: true,
                paginationDirection: 'ltr',
                autoplay: true,
                interval: 4000,
                drag: true,
                rewind: true
            }}
            >
            {
                weeklyStories?.map((story, i) => (
                    <SplideSlide key={story._id}>
                        <div className='story-content'>
                            <div className='row align-items-center'>
                            <div className='col-lg-6 pe-lg-5 px-auto'>
                                <figure className='story-image'>
                                    <div className="img-wrapper">
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/stories/thumbnails/${story?.thumbnail}`} />
                                    </div>
                                </figure>
                            </div>
                            <div className='col-lg-6 ps-lg-5 px-auto mt-lg-0 mt-5'>
                                <div className='memeber-info'>
                                    <h4 className='memmber-name'>
                                        {story?.name}
                                    </h4>
                                    <div className='memeber-details'>
                                        <h4 className='desig'>{story?.designation?.title}</h4>
                                        <p className='excerpt' dangerouslySetInnerHTML={{__html: story?.story.length < 400 ? story?.story : `${story?.story.substring(0, 450)}...` }}>
                                        </p>

                                        <Link to={`/story/${story?.slug}`} className='btn btn-custom'>
                                                <span className='text'>View story</span>
                                                <span className="icon">
                                                    <svg
                                                    version="1.0"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="96.000000pt"
                                                    height="96.000000pt"
                                                    viewBox="0 0 96.000000 96.000000"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    >
                                                    <g
                                                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                                        fill="#005eff"
                                                        stroke="none"
                                                    >
                                                        <path
                                                        d="M265 787 c-3 -7 -4 -149 -3 -317 3 -279 4 -305 20 -308 22 -4 418 297 418 318 0 18 -392 320 -415 320 -8 0 -18 -6 -20 -13z"
                                                        />
                                                    </g>
                                                    </svg>
                                                </span>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </SplideSlide>
                ))
            }
            </Splide>
      </div>
      </div>
      <figure className="top-left mb-0">
        <img src="/images/homepage/coral-red-icon.svg" alt="HUL illustration" />
      </figure>    
    </section>
  )
}

export default Discover