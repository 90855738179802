import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = () => {
  return (
    <>
        <div className="breadcrumb px-2">
            <div className="container-fluid">
                <ul className="list-unstyled d-flex align-items-center mb-0">
                    <li>
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li>
                        <Link to={'/faqs'}>FAQs</Link>
                    </li>
                </ul>
            </div>
        </div>
        <section className="page-header">
            <div className="container">
                <div className="page-header-headings">
                    <h1>FAQs</h1>
                    <p>This section includes frequently asked queries</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default PageHeader