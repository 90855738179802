import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MetaData from '../helpers/MetaData'
import notification from '../helpers/notification'
import ApiService from '../service/ApiService'
import {BsCheck2Circle} from 'react-icons/bs'
import { useState } from 'react'

const EmailVerification = ({setSideMenu, sideMenu}) => {
    // const {fetchUser} = useContext(AppContext)
    const { link } = useParams();
    const navigate = useNavigate()
    const [isSuccess, setIsSuccess] = useState(false)

    const verifyEmailAddress = async () => {
        try {
            const {message,success} = await ApiService.verifyEmailAddress(link)
            if(success) {
                setIsSuccess(true)
            }
            notification(message, "success");
            setTimeout(() => {
                navigate('/login',{replace: true})
            },2000)
        } catch (error) {
            console.log(error)
            notification(error.response.data.message, "error");
            navigate('/',{replace: true})
        }
    }

    useEffect(() => {
        if(link) {
            verifyEmailAddress()
        }
    },[link])

  return (
    <>
    <MetaData title='Verify Email | Unilever Diaries' description='Be a part of the larger comm-U-nity. Share your story! | Unilever Diaries'/>
    <Header setSideMenu={setSideMenu} />
    <main>
    <section className="email-verified-wrap auth-content">
        <div className="container">
            <div className="row">
                {
                    isSuccess ? 
                    <div className="col-lg-6 col-xl-5 mx-auto">
                        <div className="verified-success">
                            <div className="icon">
                                <BsCheck2Circle />
                            </div>
                            <h2>Email Verification Successful.</h2>

                        </div>
                    </div> : 
                    ''
                }
            </div>
        </div>
    </section>
    </main>
    <Footer sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  )
}

export default EmailVerification