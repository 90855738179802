import React from 'react'
import { Fade } from 'react-reveal'

const HowItWorks = () => {
  return (
    <section className="how-it-works">
        <div className="container">
            <div className="headings text-center">
            <Fade bottom>
                <span className="h4">Share your story in just 3 easy steps!</span>
                {/* <p>Share your story in just 3 easy steps!</p> */}
            </Fade>
            </div>
            <div className="row">
            <Fade bottom>
                <div className="col-lg-4 px-4 mb-5 mb-lg-0">
                    <div className="steps-wrapper">
                        <div className="steps-card">
                            <figure className='mb-0'>
                                <img className='w-100' src="/images/homepage/how-it-works-image-1.jpg" alt="" />
                            </figure>
                            <div className="d-flex align-items-center mt-4">
                                <div className="step-number d-flex align-items-center justify-content-center">
                                    <span>1</span>
                                </div>
                                <p className='ms-3 mb-0' >Head to the YOU page.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-4 mb-5 mb-lg-0">
                    <div className="steps-wrapper">
                        <div className="steps-card">
                            <figure className='mb-0'>
                                <img className='w-100' src="/images/homepage/how-it-works-image-2.jpg" alt="" />
                            </figure>
                            <div className="d-flex align-items-center mt-4">
                                <div className="step-number d-flex align-items-center justify-content-center">
                                    <span>2</span>
                                </div>
                                <p className='ms-3 mb-0'>Write OR record your story.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-4">
                    <div className="steps-wrapper">
                        <div className="steps-card">
                            <figure className='mb-0'>
                                <img className='w-100' src="/images/homepage/how-it-works-image-3.jpg" alt="" />
                            </figure>
                            <div className="d-flex align-items-center mt-4">
                                <div className="step-number d-flex align-items-center justify-content-center">
                                    <span>3</span>
                                </div>
                                <p className='ms-3 mb-0'>Hit submit - that's it!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            </div>
        </div>
    </section>
  )
}

export default HowItWorks