import React from 'react'
import FAQAccordion from '../components/FAQAccordion'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PageHeader from '../components/PageHeader'
import SideMenu from '../components/SideMenu'
import MetaData from '../helpers/MetaData'

const FAQ = ({ setSideMenu, sideMenu }) => {
  return (
    <>
      <MetaData title='FAQ | Unilever Diaries' description='This section includes frequently asked queries'/>
      <main>
          <Header setSideMenu={setSideMenu} />
          <PageHeader />
          <FAQAccordion />
          <Footer />
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </main>
    
    </>
  )
}

export default FAQ